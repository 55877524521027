



























import { Component, Emit, Vue } from 'vue-property-decorator';
import ErrorService from '@/services/error';
import router from '@/router';
import {
    DetailedUserDTO
} from '@/dtos/sqe-dtos';
import EditionService from '@/services/edition';


@Component({
        name: 'confirm-invitation',
    // components: {

    // },
})
export default class ConfirmInvitation extends Vue {
    // data

    protected token: string =  '';
    protected errorMessage: string = '';
    protected editionService: EditionService = new EditionService();
    protected errorService: ErrorService = new ErrorService(this);
    protected waiting: boolean = false;

    protected mounted() {
        if (!this.isLogged) {
            this.$root.$emit('bv::show::modal', 'loginModal');
        }

        const url = window.location.href;
        this.token = url.split('token/')[1];
        if (this.token === '') {
            console.error('There is no token in url');
        }
    }

    // computed: {
    protected get currentUser(): DetailedUserDTO {
            return this.$state.session.user!;
    }

    protected get isLogged(): boolean {
            return this.currentUser !== null && this.currentUser !== undefined;
    }

    // methods: {
    protected async change() {
        this.waiting = true;
        try {
            await this.editionService.confirmAddEditionEditor(this.token);
            router.push('/');
        } catch (e) {
            this.errorMessage = this.errorService.getErrorMessage(
                e.response.data
            );
        } finally {
            this.waiting = false;
        }
    }

}

