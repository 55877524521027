










































// import Vue from 'vue';
import { Component, Prop, Emit, Vue, Watch } from 'vue-property-decorator';
import Waiting from '@/components/misc/Waiting.vue';
import { EditionInfo } from '@/models/edition';
import PersonalEditions from './components/personal-editions.vue';
import PublicEditions from './components/public-editions.vue';
import { Route } from 'vue-router';

@Component({
    name: 'home',
    components: {
        Waiting,
        PersonalEditions,
        PublicEditions,
    },
})
export default class Home extends Vue {
    // component data
    // =====================

    private filter: string = '';
    public editionsLoaded = false;
    public activeTab: number = 0;

    // hooks as constructor
    // ========================
    protected created() {
        this.$state.prepare.allEditions();
        this.$state.editions.current = null;
    }

    protected async mounted() {
        if (this.$route.params.editionType === 'public') {
            this.$nextTick(() => {
                this.activeTab = 1;
            });
        }

        if (this.$route.params.editionType === 'private' && !this.user) {
            this.$router.replace('/home/public');
        }

        await this.$state.prepare.allEditions(); // Already called in create, here we just wait for it to finish
        this.editionsLoaded = true;
    }

    public get user(): boolean {
        return this.$state.session.user ? true : false;
    }

    // methods & event handlers
    // ==================================

    public nameMatch(name: string): boolean {
        return name.toLowerCase().indexOf(this.filter.toLowerCase()) !== -1;
    }

    public get personalEditionsCount() {
        return this.$state.editions.items.filter((ed) => !ed.isPublic).length;
    }

    public get publicEditionsCount() {
        return this.$state.editions.items.filter((ed) => ed.isPublic).length;
    }

    public onActivateTab(newTab: number, prevTab: number) {
        if (prevTab === -1) {
            return;
        }
        if (newTab === 0 && this.$route.params.editionType !== 'private') {
            this.$router.push('/home/private');
        } else if (
            newTab === 1 &&
            this.$route.params.editionType !== 'public'
        ) {
            this.$router.push('/home/public');
        }
    }

    @Watch('$route')
    protected onRouteChanged(to: Route, from: Route) {
        if (to.name !== 'home') {
            return; // Changed to some other page, ignore it.
        }

        if (to.params.editionType === 'public') {
            this.activeTab = 1;
        } else {
            this.activeTab = 0;
        }
    }
}
