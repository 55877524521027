





























import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import ArtefactService from '@/services/artefact';
import { Artefact } from '@/models/artefact';
import ArtefactImage from '@/components/artefact/artefact-image.vue';

@Component({
    name: 'artefact-card',
    components: {
        ArtefactImage,
    }
})
export default class ArtefactCard extends Vue {
    @Prop() public readonly artefact!: Artefact;
    public newArtefactName: string = '';
    public observed = false;
    private prevLineMenuId: string = '';
    private intersectionObserver?: IntersectionObserver;
    private artefactService = new ArtefactService();

    public mounted() {
        this.intersectionObserver = new IntersectionObserver((entries) => this.onObserved(entries), {
            root: undefined,
            rootMargin: '0px',
            threshold: 0.1,
        });
        this.intersectionObserver.observe(this.$refs.card as Element);
        this.newArtefactName = this.artefact.name;
    }

    public async renameArtefact() {
        this.artefact.name = this.newArtefactName;
        await this.artefactService.changeArtefact(
            this.editionId,
            this.artefact
        );
        this.$root.$emit('bv::hide::popover', this.prevLineMenuId);

    }
    public closeLineMenu() {
        this.$root.$emit('bv::hide::popover', this.prevLineMenuId);
    }

    public openLineMenu(event: MouseEvent, artefactId: any) {
        event.preventDefault();
        this.$root.$emit('bv::show::popover', artefactId);
        this.prevLineMenuId = artefactId;
    }

    private onObserved(entries: IntersectionObserverEntry[]) {
        if (entries.length !== 1) {
            console.warn('Intersection handler received numerous entries, 1 expected', entries);
        }

        const entry = entries[0];
        if (!entry.isIntersecting) {
            return;
        }

        // We get here whether there is an intersection, or we've received the wrong number of entries. Either way,
        // we can load the image and stop observing.
        this.observed = true;

        this.intersectionObserver!.disconnect();
        this.intersectionObserver = undefined;
    }

    public get editionId(): number {
        return parseInt(this.$route.params.editionId);
    }

    public destroyed() {
        if (this.intersectionObserver) {
            this.intersectionObserver.disconnect();
            this.intersectionObserver = undefined;
        }
    }

}

