var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.waiting)?_c('div',{staticClass:"col"},[_c('Waiting')],1):_vm._e(),(!_vm.waiting && _vm.imagedObject)?_c('div',{ref:"imagedObjectGrid",attrs:{"id":"imaged-object-grid"}},[_c('imaged-object-editor-toolbar',{attrs:{"id":"imaged-object-toolbar","imagedObject":_vm.imagedObject,"artefacts":_vm.visibleArtefacts,"artefact":_vm.artefact,"artefactId":_vm.artefactId,"side":_vm.side,"status-indicator":_vm.operationsManager,"modes":_vm.editList},on:{"onSideArtefactChanged":function($event){return _vm.sideArtefactChanged($event)}}},[_c('toolbox',[(_vm.canEdit)?_c('b-btn',{directives:[{name:"b-modal",rawName:"v-b-modal.modal",value:('newModal'),expression:"'newModal'",modifiers:{"modal":true}}],staticClass:"btn",attrs:{"variant":"outline-secondary"}},[_vm._v(_vm._s(_vm.$t('misc.new')))]):_vm._e()],1)],1),(_vm.imagedObject)?_c('div',{attrs:{"id":"imaged-object-title"}},[_vm._v(" "+_vm._s(_vm.imagedObject.id)+" "),_c('edition-icons',{attrs:{"edition":_vm.edition,"show-text":true}})],1):_vm._e(),_c('div',{attrs:{"id":"imaged-object-container"}},[(_vm.masterImage)?_c('zoomer',{staticClass:"img-obj-container",attrs:{"zoom":_vm.zoomLevel},on:{"new-zoom":function($event){return _vm.onNewZoom($event)}}},[_c('svg',{staticClass:"overlay",attrs:{"height":_vm.actualHeight,"width":_vm.actualWidth,"viewBox":("0 0 " + _vm.actualWidth + " " + _vm.actualHeight)}},[(_vm.artefact)?_c('g',{attrs:{"transform":_vm.transform,"id":"transform-root"}},[_c('image-layer',{attrs:{"width":_vm.imageWidth,"height":_vm.imageHeight,"params":_vm.params,"editable":_vm.canEdit,"clipping-mask":_vm.artefact.mask}}),_vm._l((_vm.visibleArtefacts),function(art){return _c('artefact-layer',{key:art.id,attrs:{"selected":art.id === _vm.artefact.id,"artefact":art,"color":_vm.removeColor ? 'none' : _vm.getArtefactColor(art)}})}),(_vm.canEdit && _vm.artefact)?_c('boundary-drawer',{attrs:{"color":_vm.isErasing
                                    ? 'black'
                                    : _vm.getArtefactColor(_vm.artefact),"transform-root-id":"transform-root"},on:{"new-polygon":function($event){return _vm.onNewPolygon($event)}}}):_vm._e()],2):_vm._e()])]):_vm._e()],1),(_vm.displayResizeBar)?_c('resize-bar',{attrs:{"gridElement":_vm.$refs.imagedObjectGrid,"storageKey":"image-object-left-pane-width"}}):_vm._e(),_c('div',{attrs:{"id":"imaged-object-artefacts"}},_vm._l((_vm.visibleArtefacts),function(art){return _c('div',{key:art.id,class:{
                    selectedRow: art.id === _vm.artefact.id,
                }},[_c('b-row',{staticClass:"py-2"},[_c('b-col',{staticClass:"col-2 col-xl-3 col-lg-2 pl-4 pt-1"},[(_vm.renameInputActive !== art)?_c('span',{staticClass:"rename-art",class:{
                                selected: art.id === _vm.artefact.id,
                            },style:({
                                color: _vm.getArtefactColor(art),
                                backgroundColor: _vm.getArtefactColor(art),
                            }),on:{"click":function($event){return _vm.onArtefactChanged(art)}}},[_vm._v("   ")]):_vm._e()]),_c('b-col',{staticClass:"col-5 col-xl-4 col-lg-4 col-md-6"},[(_vm.renameInputActive !== art)?_c('span',{staticClass:"select-art-name",class:{
                                selected: art.id === _vm.artefact.id,
                            },on:{"click":function($event){return _vm.onArtefactChanged(art)}}},[_vm._v(_vm._s(art.name))]):_vm._e()]),_c('b-col',{staticClass:"col-4 col-xl-5 col-lg-5 col-md-4 mr-0 px-0"},[(_vm.canEdit)?_c('div',[(_vm.renameInputActive !== art)?_c('b-button',{staticClass:"btn btn-sm mb-1",attrs:{"id":"rename"},on:{"click":function($event){return _vm.inputRenameChanged(art)}}},[_vm._v("Rename")]):_vm._e(),(_vm.renameInputActive === art)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(art.name),expression:"art.name"}],domProps:{"value":(art.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(art, "name", $event.target.value)}}}):_vm._e(),(
                                    !_vm.renaming && _vm.renameInputActive === art
                                )?_c('b-button',{staticClass:"btn btn-sm",attrs:{"disabled":!art.name},on:{"click":_vm.onRename}},[_vm._v("Rename")]):_vm._e(),(_vm.renameInputActive === art && _vm.renaming)?_c('b-button',{staticClass:"disable btn btn-sm",attrs:{"disabled":""}},[_vm._v(" Renaming... "),_c('font-awesome-icon',{attrs:{"icon":"spinner","spin":""}})],1):_vm._e(),_c('b-button',{staticClass:"btn btn-sm ml-2 mb-1",on:{"click":function($event){return _vm.onDeleteArtefact(art)}}},[_vm._v("Delete")])],1):_vm._e()])],1)],1)}),0)],1):_vm._e(),_c('b-modal',{ref:"newArtRef",attrs:{"id":"newModal","title":_vm.$t('home.newArtefact'),"ok-title":_vm.$t('misc.create'),"cancel-title":_vm.$t('misc.cancel'),"ok-disabled":_vm.waiting || !_vm.canCreate,"cancel-disabled":_vm.waiting},on:{"shown":_vm.newModalShown,"ok":_vm.newArtefact}},[_c('form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.newArtefact.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":_vm.$t('home.newArtefactName'),"label-for":"newArtefactName"}},[_c('b-form-input',{ref:"newArtefactName",attrs:{"id":"newName","type":"text","required":"","placeholder":_vm.$t('home.newArtefactName')},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.newArtefact.apply(null, arguments)}},model:{value:(_vm.newArtefactName),callback:function ($$v) {_vm.newArtefactName=$$v},expression:"newArtefactName"}})],1),(_vm.waiting)?_c('p',[_vm._v(" "+_vm._s(_vm.$t('home.creatingNewArtefact'))+"... "),_c('font-awesome-icon',{attrs:{"icon":"spinner","spin":""}})],1):_vm._e(),(_vm.errorMessage)?_c('p',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }