











import { Component, Prop, Vue } from 'vue-property-decorator';
import { EditionInfo } from '@/models/edition';

@Component({
    name: 'edition-icons',
})
export default class EditionIcons extends Vue {
    @Prop() public edition!: EditionInfo;
    @Prop({ default: false }) public showText!: boolean;

    public get readOnly() {
        return this.edition.permission.readOnly;
    }

    public get shared() {
        // Some shares have no read permission, which means they have been revoked.
        return this.edition.shares.filter(share => share.permissions.mayRead).length > 1;
    }
}
