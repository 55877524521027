
















import { Component, Prop, Vue } from 'vue-property-decorator';
import { TextFragment } from '@/models/text';
import TextLine from '@/components/text/text-line.vue';

@Component({
    name: 'text-fragment',
    components: {
        'text-line': TextLine
    },
})
export default class TextFragmentComponent extends Vue {
    @Prop() public fragment!: TextFragment;
     @Prop() public fontSize!: number;
}

