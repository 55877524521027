






































































































import { Component, Vue } from 'vue-property-decorator';
import ArtefactImage from '@/components/artefact/artefact-image.vue';
import { Artefact } from '@/models/artefact';
import { Side } from '@/models/misc';

@Component({
    name: 'add-artefact-modal',
    components: {
        'artefact-image': ArtefactImage,
    },
})
export default class AddArtefactModal extends Vue {
    private artefact: Artefact | undefined = {} as Artefact;
    public chekedArtefacts: number[] = [];
    public searchValue: string = '';
    private isLoaded = false;

    private async scrollModalShown() {
        this.isLoaded = false;
        await this.$state.prepare.artefacts(this.$state.editions.current!.id);
    }

    private checkedAllSide(side: Side) {
        this.chekedArtefacts = this.nonPlacedArtefacts
            .filter(
                (x: Artefact) =>
                    x.name
                        .toLowerCase()
                        .includes(this.searchValue.toLowerCase()) &&
                    x.side === side
            )
            .map((x) => x.id);
    }

    private get artefacts() {
        return this.$state.artefacts.items || [];
    }

    private get nonPlacedArtefacts() {
        return this.artefacts.filter((x) => !x.isPlaced);
    }

    public get filteredArtefacts() {
        this.chekedArtefacts = [];
        return this.nonPlacedArtefacts.filter(
            (x: Artefact) =>
                x.name.toLowerCase().includes(this.searchValue.toLowerCase()) &&
                x.side === x.side
        );
    }

    public isSelectedArtefact(artId: number): boolean {
        return this.artefact!.id === artId;
    }

    private selectArtefact(id: number) {
        this.isLoaded = false;

        setTimeout(() => {
            this.artefact = this.artefacts.find((a) => a.id === id);
            this.isLoaded = true;
        }, 0);
    }

    private closeModal() {
        (this.$refs.addArtefactModalRef as any).hide(this.chekedArtefacts);
        this.uncheckAll();
    }

    private uncheckAll() {
        this.searchValue = '';
        this.chekedArtefacts = [];
    }
}
