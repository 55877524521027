

































































































import { DetailedSearchRequestDTO } from '@/dtos/sqe-dtos';
import SearchService from '@/services/search';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { SearchFormData } from './types';
import Waiting from '@/components/misc/Waiting.vue';
@Component({
    name: 'search',
})
export default class SearchForm extends Vue {
    private searchService: SearchService = new SearchService();
    private searchData: SearchFormData = new SearchFormData();
    @Prop({ default: false })
    private disabled!: boolean;

    private mounted() {
        this.searchData = new SearchFormData();
    }

    private textToArray(
        input: string,
        field: 'textReference' | 'artefactDesignation'
    ) {
        const list = input.split('\n').filter((s) => !!s); // Remove empty items from the list
        Vue.set(this.searchData, field, list);
    }

    private get noSearch() {
        return (
            this.disabled ||
            (!this.searchData.textDesignation &&
                !this.searchData.imageDesignation &&
                !this.searchData.textReference?.length &&
                !this.searchData.artefactDesignation?.length)
        );
    }

    @Emit()
    private search() {
        return this.searchData;
    }
}
