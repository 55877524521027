




































































































































































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { Line, TextDirection, TextFragment } from '@/models/text';
import TextSign from '@/components/text/text-sign.vue';
import {
    EditorDTO,
    LineDTO,
    QwbParallelListDTO,
    LineTextDTO,
    SignDTO
} from '@/dtos/sqe-dtos';
import QwbProxyService from '@/services/qwb-proxy';
import EditionService from '@/services/edition';
import TextService from '@/services/text';

@Component({
    name: 'text-line',
    components: {
        'text-sign': TextSign
    }
})
export default class TextLineComponent extends Vue {
    // public text_service: TextService = new TextService();
    @Prop() public isEditMode!: boolean;
    @Prop() public line!: Line;
    @Prop({
        default: 'rtl'
    })
    public direction!: TextDirection;
    private parallels: QwbParallelListDTO | null = null;
    private variants: DetailedLineTextDTO[] = [];
    private prevLineMenuId: string = '';
    private get editionId() {
        return parseInt(this.$route.params.editionId);
    }
    private async showParallels() {
        this.closeLineMenu();
        this.$bvModal.show(`parallel-line-${this.line.lineId}`);
        if (this.parallels === null) {
            const qwbWordIds = this.line.signs.flatMap(x =>
                x.signInterpretations.flatMap(y => y.qwbWordIds)
            );
            if (qwbWordIds.length > 0) {
                const qwbStartId = qwbWordIds[0];
                const qwbEndId = qwbWordIds[qwbWordIds.length - 1];
                if (
                    qwbStartId !== qwbEndId &&
                    qwbStartId !== 0 &&
                    qwbEndId !== 0
                ) {
                    const qps = new QwbProxyService();
                    this.parallels = await qps.getQwbParallelText(
                        qwbStartId,
                        qwbEndId
                    );
                }
            }
        }
    }

    @Emit()
    private lineChange(event: any) {
        return event.target.textContent;
    }

    private checkEnter(event: any) {
        event.preventDefault();
        event.currentTarget.blur();
    }
    private openEditLineModal(line: Line) {
        this.$state.textFragmentEditor.selectSign(
            line.signs[0].signInterpretations[0]
        );
        this.$root.$emit('bv::show::modal', 'editLineModal');
    }
    private addLineBefore(line: Line) {
        console.log(this.$state.textFragmentEditor.selectedSignInterpretations)
        this.$state.textFragmentEditor.selectSign(
            line.signs[0].signInterpretations[0]
        );
        this.$root.$emit('bv::show::modal', 'addLineModal', 'before');
    }
    public deleteLine(line: Line) {
        this.$state.textFragmentEditor.selectSign(
        line.signs[0].signInterpretations[0]
        );
        this.$root.$emit('bv::show::modal', 'deleteLineModal');
        // const ts = new TextService();
        // // add operation on delete 
        // ts.deleteLine(line.editorId, line.lineId);
    }
    private addLineAfter(line: Line) {
        console.log(this.$state.textFragmentEditor.selectedSignInterpretations)
        this.$state.textFragmentEditor.selectSign(
        line.signs[0].signInterpretations[0]
        );
        this.$root.$emit('bv::show::modal', 'addLineModal', 'after');
    }

    private onPaste(event: any) {
        const paste = (
            event.clipboardData || (window as any).ClipboardItem
        ).getData('text');
        const selection = window.getSelection();
        if (selection) {
            if (!selection.rangeCount) {
                return false;
            }
            selection.deleteFromDocument();
            selection.getRangeAt(0).insertNode(document.createTextNode(paste));
        }

        event.preventDefault();
    }

    private async showVariants() {
        this.closeLineMenu();
        this.$bvModal.show(`variant-line-${this.line.lineId}`);
        if (this.variants.length === 0) {
            const es = new EditionService();
            const currentEdition = await es.getSingleEditions(this.editionId);
            const variantEditionList = await es.getManuscriptEditions(
                currentEdition.primary.manuscriptId
            );
            const variantEditions = variantEditionList.editions.flatMap(
                x => x[0]
            );
            const ts = new TextService();
            for (const variantEdition of variantEditions) {
                if (variantEdition.id !== this.editionId) {
                    try {
                        const line = (await ts.getLineText(
                            variantEdition.id,
                            this.line.lineId
                        )) as DetailedLineTextDTO;
                        line.editionId = variantEdition.id;
                        line.name = variantEdition.name;
                        this.variants.push(line);
                    } catch {
                        this.variants.push({
                            editionId: variantEdition.id,
                            name: variantEdition.name,
                            licence: variantEdition.copyright,
                            editors: {} as { [key: string]: EditorDTO },
                            lineId: this.line.lineId,
                            lineName: 'NA',
                            editorId: 0,
                            signs: []
                        } as DetailedLineTextDTO);
                    }
                }
            }
        }
    }
    private openLineMenu(event: MouseEvent, lineMenuId: string) {
        if (this.isEditMode) {
            return;
        }
        // prevent usual menu to display
        event.preventDefault();
        this.$root.$emit('bv::show::popover', lineMenuId);
        this.prevLineMenuId = lineMenuId;
    }
    private closeLineMenu() {
        this.$root.$emit('bv::hide::popover', this.prevLineMenuId);
    }
    private focusPopover() {
        (this.$refs.lineMenu as any).focus();
    }
}
interface DetailedLineTextDTO extends LineTextDTO {
    name: string;
    editionId: number;
}
