


























import { Component, Vue } from 'vue-property-decorator';
import Waiting from '@/components/misc/Waiting.vue';
import ArtefactCard from './artefact-card.vue';
import { Artefact } from '@/models/artefact';
import SearchBar from '@/components/search-bar.vue';
import { SearchBarParams } from '@/components/search-bar.vue';
import { SearchBarValue } from '@/state/utilities';

@Component({
    name: 'edition-artefacts',
    components: {
        Waiting,
        ArtefactCard,
        SearchBar,
    },
})
export default class EditionArtefacts extends Vue {
    public filteredArtefacts: Artefact[] = [];
    public searchValue: SearchBarValue = { side: 'recto and verso'};
    public editionId: number = 0;
    public searchBarParams: SearchBarParams = {
        filter: true,
        sort: false,
        side: true,
    };

    public get sortedFragments(): Artefact[] {
        return this.filteredArtefacts.sort( (a: Artefact, b: Artefact) => {
                        return (a as any).name.localeCompare( (b as any).name,  undefined,
                        {
                            numeric: true,
                            sensitivity: 'base'
                        });
                    });
    }

    public getFilteredArtefacts(): Artefact[] {
        return this.$state.artefacts.items
            .filter((art: Artefact) => {
                let filter = true;

                if (art.isVirtual) {
                    return false;
                }

                if (
                    this.searchValue.side &&
                    this.searchValue.side !== 'recto and verso'
                ) {
                    filter = filter && art.side === this.searchValue.side;
                }
                if (
                    this.searchValue.filter
                    ) {
                    filter =
                        filter &&
                        `${art.name} - ${art.side}`
                            .toLowerCase()
                            .includes(this.searchValue.filter.toLowerCase());
                }

                return filter;
            })
            .sort((a: Artefact, b: Artefact) => {
                if (this.searchValue.sort) {
                    return (a as any)[this.searchValue.sort] >
                        (b as any)[this.searchValue.sort]
                        ? 1
                        : -1;
                } else {
                    return 1;
                }
            });
    }

    public onArtefactsSearch(searchEvent: SearchBarValue) {
        this.searchValue = searchEvent;
        this.filteredArtefacts = this.getFilteredArtefacts();
    }

    protected async mounted() {
        this.editionId = parseInt(this.$route.params.editionId, 10);
        await this.$state.prepare.edition(this.editionId);
        await this.$state.prepare.artefacts(this.editionId);
        this.filteredArtefacts = this.getFilteredArtefacts();
   }

   private get containerRef() {
       return this.$refs.container as Element;
   }
}

