
























































import { Component, Prop, Emit, Vue } from 'vue-property-decorator';

@Component({
    name: 'EulaModal',
    components: {
    }
})

export default class EulaModal extends Vue {
}

