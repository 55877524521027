








































































































































































































































































































































import { Component, Prop, Emit, Model, Vue } from 'vue-property-decorator';
import { ScrollEditorState } from '@/state/scroll-editor';
import { ArtefactDTO, EditionManuscriptMetricsDTO } from '@/dtos/sqe-dtos';
import { ScrollEditorParams, ScrollEditorOpMode } from '../artefact-editor/types';
import { Placement } from '@/utils/Placement';
import { Artefact } from '@/models/artefact';
import { Point } from '../../utils/helpers';
import {
    ArtefactPlacementOperation,
    ArtefactPlacementOperationType,
    // EditGroupOperation,
    EditionMetricOperation,
    GroupPlacementOperation,
    ScrollEditorOperation,
} from './operations';

@Component({
    name: 'manuscript-toolbar',
    components: {
    },
})

export default class ManuscriptToolbar extends Vue {

    private created() {
        // Corrupted state event listener
        this.$root.$on('delete-key-pressed', () => this.removeArtefactOrGroup());
    }

    protected beforeDestroy() {
        this.$root.$off('delete-key-pressed', () => this.removeArtefactOrGroup());
    }

    // @Prop() private params!: ScrollEditorParams;
    @Prop({ default: -1 }) public artefactId!: number;
    private selectedSide: string = 'left';
    private metricsInput: number = 1;
    private artifactMaxWidth: number = 0;
    private artifactMaxHeight: number = 0;
    public selectedArtifactsSize: {width: number ,height: number }[] = [];
    private groupSize: {width: number , height: number} = {width: 0, height: 0};


    // Calculates the pixels per inch (PPI) of the device screen
    // It uses the Pythagorean theorem to find the diagonal length of the screen, and then divides it by 15 to get the PPI.
    // The diagonal length is calculated by finding the square root of the sum of the squares of the width and height of the screen (in pixels).
    private ppi = Math.sqrt((Math.pow(window.screen.width, 2)) + (Math.pow(window.screen.height, 2)) ) / 15;

    private sidesOptions: Array<{ text: string; value: string }> = [
        { text: 'Left', value: 'left' },
        { text: 'Right', value: 'right' },
        { text: 'Top', value: 'top' },
        { text: 'Down', value: 'down' },
    ];

    private keyboardInput: boolean = true;
    private zoomDelta!: number;

    @Emit()
    private saveGroup() {
        return true;
    }
    @Emit()
    private manageGroup() {
        return true;
    }
    @Emit()
    private newOperation(op: ScrollEditorOperation) {
        return op;
    }
    @Emit()
    private cancelGroup() {
        return true;
    }

    private get edition() {
        return this.$state.editions.current! || {};
    }

    private get scrollEditorState(): ScrollEditorState {
        return this.$state.scrollEditor;
    }

    private get params(): ScrollEditorParams {
        return this.scrollEditorState.params || new ScrollEditorParams();
    }

    private get pointerPositionX() {
        return (
            this.scrollEditorState.pointerPosition.x /
            this.params.zoom /
            this.edition.ppm
        ).toFixed(2);
    }

    private get pointerPositionY() {
        return (
            this.scrollEditorState.pointerPosition.y /
            this.params.zoom /
            this.edition.ppm
        ).toFixed(2);
    }

    private get viewportSizeWidth() {
        return Math.round(
            this.scrollEditorState.viewport!.width / this.edition.ppm
        );
    }
    private get viewportSizeHeight() {
        return Math.round(
            this.scrollEditorState.viewport!.height / this.edition.ppm
        );
    }

    private get artefacts() {
        return this.$state.artefacts.items || [];
    }

    public get selectedArtefacts() {
        this.updateSelectedArtefactsSizes();
        this.getGroupSize(this.scrollEditorState.selectedArtefacts);
        return this.scrollEditorState.selectedArtefacts;
    }
    public updateSelectedArtefactsSizes(): void {
        // when we update the selectedArtifacts ,we update the selectedArtifactsSize (to display each artifact width and heigth)
        this.selectedArtifactsSize = [];
        const arts = this.scrollEditorState.selectedArtefacts;
        for (let i = 0; i < arts.length; i++) {
            const artifactSize = {
                width: this.getArtefactWidth(arts[i]),
                height: this.getArtefactHeight(arts[i])
            };
            if (this.selectedArtifactsSize.indexOf(artifactSize) === -1) {
                this.selectedArtifactsSize.push(artifactSize);
            }
        }
    }
    private get placedArtefacts() {
        return this.artefacts.filter((x) => x.isPlaced);
    }

    private get artefact() {
        return this.$state.artefacts.find(this.artefactId);
    }

    public get selectedArtefact() {
        const artifact = this.scrollEditorState.selectedArtefact;
        if (artifact) {
            this.artifactMaxWidth = this.getArtefactWidth(artifact);
            this.artifactMaxHeight = this.getArtefactHeight(artifact);
        }
        return this.scrollEditorState.selectedArtefact;
    }

    private onDisplayROIs(value: boolean) {
        this.scrollEditorState.displayRois = value;
    }
    private onDisplayReconstructedText(value: boolean) {
        this.scrollEditorState.displayReconstructedText = value;
    }

    private get isDisplayText(): boolean {
        return this.scrollEditorState.displayText;
    }
    private onDisplayText(value: boolean) {
        this.scrollEditorState.displayText = value;
    }


    public get selectedGroup() {
        return this.scrollEditorState.selectedGroup;
    }
    private openAddArtefactModal() {
        this.$root.$emit('bv::show::modal', 'addArtefactModal');
    }


    private removeArtefactOrGroup() {
        if (this.selectedArtefact) {
            const operation = this.createOperation(
                'delete',
                Placement.empty,
                this.selectedArtefact,
                false
            );

            console.debug('removeArtefactOrGroup creating new operation ', operation);
            this.newOperation(operation);
        }

        if (this.selectedGroup) {
            const operations: ScrollEditorOperation[] = [];

            this.selectedArtefacts.forEach((art: Artefact) => {
                operations.push(
                    this.createOperation('delete', Placement.empty, art, false)
                );
            });

            const operation = new GroupPlacementOperation(
                this.selectedGroup.groupId,
                operations,
                'delete'
            );

            this.newOperation(operation);
            this.deleteGroup(this.selectedGroup.groupId);
        }
    }


    private deleteGroup(groupId: number) {
        const groupArtefact = this.edition.artefactGroups.find(
            (x) => x.groupId === groupId
        );
        if (groupArtefact) {
            groupArtefact.artefactIds = [];
        }
        if (this.selectedGroup) { // making the artefactsIds empty make sure we get rid of the artefacts group we removed
            this.selectedGroup.artefactIds = [];
        }
    }



    private get mode(): ScrollEditorOpMode {
        return this.params!.mode;
    }

    private setMode(mode: ScrollEditorOpMode) {
        this.params.mode = mode;
    }

    private getArtefactHeight(artefact: Artefact): number {
        // Artifact svg property contains the string representation of the SVG polygon.
        // It looks like this "M3349.131736526946 9179.191616766468L3349.131736526946 9193.74251497006L3353.982035928143"
        const pointsAttribute = artefact.mask.svg;
        // The result of splitting the string by "L" is an array of strings in the format "x y".(each string is a polygon vertex)
        const points = pointsAttribute.split('L');
        let maxY = -Infinity;
        let minY = Infinity;
        for (const point of points) {
            // The [x, y] assignment syntax is used to assign the first and second elements of the resulting array to the variables
            // x and y respectively which were converting to number first.
            const [x, y] = point.split(' ').map(n => parseFloat(n));
            maxY = Math.max(maxY, y);
            minY = Math.min(minY, y);
        }
        const maxHeight = maxY - minY;
        return this.convertToMM(maxHeight);
    }
    private getArtefactWidth(artifact: Artefact): number {
        // Artifact svg property contains the string representation of the SVG polygon.
        // It looks like this "M3349.131736526946 9179.191616766468L3349.131736526946 9193.74251497006L3353.982035928143"
        const pointsAttribute = artifact.mask.svg;
        // The result of splitting the string by "L" is an array of strings in the format "x y".(each string is a polygon vertex)
        const points = pointsAttribute.split('L');
        let maxX = -Infinity;
        let minX = Infinity;
        for (let point of points) {
            if (point[0] === 'M') { // we remove the M from the first string (which messes with the calculation).
                point = point.substring(1);
            }
            // The [x, y] assignment syntax is used to assign the first and second elements of the resulting array to the variables
            // x and y respectively which were converting to number first.
            const [x, y] = point.split(' ').map(n => parseFloat(n));
            maxX = Math.max(maxX, x);
            minX = Math.min(minX, x);
        }
        const maxWidth = maxX - minX;
        return this.convertToMM(maxWidth);
    }
    private convertToMM(number: number): number {
        return Math.round((number / this.ppi * 2.54) * 100) / 100;
    }
    private getGroupSize(arts: Artefact[]): void {
        let minimumsX: number[] = [];
        let minimumsY: number[] = [];

        let maximumsX: number[] = [];
        let maximumsY: number[] = [];
        // We go over all the artifacts and get the "extreme left one" and the "extreme right one", then get the width between them .(Same for height)
        for (let i = 0; i < arts.length; i++) {
            minimumsX.push(this.convertToMM(arts[i].placement.translate.x));
            maximumsX.push(this.convertToMM(arts[i].placement.translate.x) + this.convertToMM(arts[i].boundingBox.width));
            minimumsY.push(this.convertToMM(arts[i].placement.translate.y));
            maximumsY.push(this.convertToMM(arts[i].placement.translate.y) + this.convertToMM(arts[i].boundingBox.height));
        }
        const globalWidth = Math.max.apply(Math, maximumsX) - Math.min.apply(Math, minimumsX);
        const globalHeight = Math.max.apply(Math, maximumsY) - Math.min.apply(Math, minimumsY);
        this.groupSize = {width: Math.round( globalWidth * 100) / 100,
                height: Math.round( globalHeight * 100) / 100
                };
    }
    private resizeScroll(direction: number) {
        const newMetrics: EditionManuscriptMetricsDTO = {
            ...this.edition.metrics,
        };

        switch (this.selectedSide) {
            case 'left':
            case 'right':
                newMetrics.width += +this.metricsInput * direction;
                if (this.selectedSide === 'left') {
                    newMetrics.xOrigin += +this.metricsInput * direction * -1;
                }
                break;

            case 'top':
            case 'down':
                newMetrics.height += +this.metricsInput * direction;
                if (this.selectedSide === 'top') {
                    newMetrics.yOrigin += +this.metricsInput * direction * -1;
                }
                break;
        }
        if (
            direction === -1 &&
            !this.allowResizing(this.selectedSide, newMetrics)
        ) {
            this.$toasted.error(
                'Cannot resize scroll because artefacts will be cropped',
                { duration: 3000 }
            );
        } else {
            const metricsOperation = new EditionMetricOperation(
                this.edition.id,
                this.edition.metrics,
                newMetrics
            );
            this.edition.metrics = { ...newMetrics };
            this.newOperation(metricsOperation);
            this.$emit('onMetricsChange');
        }
    }

    private allowResizing(
        side: string,
        newMetrics: EditionManuscriptMetricsDTO
    ): boolean {
        // left : XOrigin <= Xmin
        if (side === 'left') {
            const minX =
                Math.min(
                    ...this.placedArtefacts.map(
                        (art) => art.placement.translate.x!
                    )
                ) / this.edition.ppm;
            return newMetrics.xOrigin <= minX;
        }

        // right : Xmax <= width
        if (side === 'right') {
            const maxX =
                Math.max(
                    ...this.placedArtefacts.map(
                        (art) =>
                            art.placement.translate.x! + art.boundingBox.width
                    )
                ) / this.edition.ppm;
            return maxX - newMetrics.xOrigin <= newMetrics.width;
        }

        // top : YOrigin <= Ymin
        if (side === 'top') {
            const minY =
                Math.min(
                    ...this.placedArtefacts.map(
                        (art) => art.placement.translate.y!
                    )
                ) / this.edition.ppm;
            return newMetrics.yOrigin <= minY;
        }

        // down : Ymax <= height
        if (side === 'down') {
            const maxY =
                Math.max(
                    ...this.placedArtefacts.map(
                        (art) =>
                            art.placement.translate.y! + art.boundingBox.height
                    )
                ) / this.edition.ppm;
            return maxY - newMetrics.yOrigin <= newMetrics.height;
        }

        return true;
    }


    private setZIndex(zIndexDirection: number) {
        const operations: ScrollEditorOperation[] = [];
        let operation: ScrollEditorOperation = {} as ScrollEditorOperation;
        const placedArtefacts = this.$state.artefacts.items.filter(
            (x) => x.isPlaced
        );
        const artefactsZOrders = placedArtefacts.map((x) => x.placement.zIndex);
        const zIndex =
            zIndexDirection < 0
                ? Math.min(...artefactsZOrders) - 1
                : Math.max(...artefactsZOrders) + 1;
        if (this.selectedArtefact) {
            const trans = this.selectedArtefact.placement.clone();
            trans.zIndex = zIndex;
            operation = this.createOperation(
                'z-index',
                trans,
                this.selectedArtefact
            );
        }
        if (this.selectedGroup) {
            this.selectedArtefacts.forEach((art) => {
                const trans = art.placement.clone();
                trans.zIndex = zIndex;
                operations.push(this.createOperation('z-index', trans, art));
            });
            operation = new GroupPlacementOperation(
                this.selectedGroup.groupId,
                operations
            );
        }
        this.newOperation(operation);
    }


    private createOperation(
        opType: ArtefactPlacementOperationType,
        newPlacement: Placement,
        artefact: Artefact,
        newIsPlaced: boolean = true
    ): ArtefactPlacementOperation {
        const op = new ArtefactPlacementOperation(
            artefact.id,
            opType,
            artefact.placement,
            newPlacement,
            artefact.isPlaced,
            newIsPlaced
        );
        artefact.placement = newPlacement;
        artefact.isPlaced = newIsPlaced;
        return op;
    }
}
