







































import { Component, Vue, Watch } from 'vue-property-decorator';
import { VirtualArtefactEditor } from '@/services/virtual-artefact';
import { Artefact } from '@/models/artefact';

@Component({
    name: 'edit-virtual-artefact-text-pane',
})
// export default class EditVirtualArtefactTextModal extends Vue {
export default class EditVirtualArtefactTextPane extends Vue {
    private text = '';
    private originalText = '';
    private editor?: VirtualArtefactEditor;
    private originalArtefact?: Artefact;

    private mounted() {
        this.onShown();
    }

    private onShown() {

        if (!this.$state.textFragmentEditor.editedVirtualArtefact) {
            this.$state.corrupted('EditorVirtualArtefact modal is shown with no edited virtual artefact');
        }
        this.originalArtefact = this.$state.textFragmentEditor.editedVirtualArtefact;

        this.editor = new VirtualArtefactEditor(this.$state.textFragmentEditor.editedVirtualArtefact);

        // Hide our artefact, and show the shadow artefact
        this.originalArtefact.isPlaced = false;
        this.text = this.originalText = this.editor.text;
    }

    private onHide() {

        if (!this.editor) {
            return;
        }

        this.editor.hide();

        this.originalArtefact!.isPlaced = true;
        this.$emit('close', { text: this.text, originalText: this.originalText, editor: this.editor });

        this.editor = undefined;
    }

    private destroyed() {
        this.onHide();
    }

    private onTextChanged() {
        // Tsvia: If the new text contains illegal characters (non Hebrew and not space), remove the illegal
        // characters
        if (!this.editor) {
            throw new Error('Editor object disappeared');
        }

        const hebTextOnly = this.stripNonHebChars(this.text);
        this.editor.text = hebTextOnly;

        this.$nextTick(() => {
            this.text = hebTextOnly;
        });
    }

    private stripNonHebChars(input: string): string {
        const hebrewAlphabet = 'אבגדהוזחטיכךלמנסעפצקרשתםןףץ ';
        let output = '';

        for ( const letter of input ) {
            if ( hebrewAlphabet.indexOf( letter ) !== -1) {
                output += letter;
            }
        }

        return output;
    }
}
