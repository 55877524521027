









































































































import { Component, Prop, Emit, Vue } from 'vue-property-decorator';

import SessionService from '@/services/session';
import ErrorService from '@/services/error';
import { NewUserRequestDTO } from '@/dtos/sqe-dtos';
import router from '@/router';



@Component({
    name: 'registration',
})

export default class Registration extends Vue {

    // data

    protected forename: string = '';
    protected surname: string = '';
    protected email: string = '';
    protected password: string = '';
    protected repassword: string = '';
    protected organization: string = '';
    protected errorMessage: string = '';
    protected sessionService: SessionService = new SessionService();
    protected errorService: ErrorService = new ErrorService(this);
    protected waiting: boolean = false;
    protected termsOfUse: boolean = false;


    // computed

    public get disabledReg(): boolean {
        return (
            this.password !== this.repassword ||
            !this.forename ||
            !this.surname ||
            !this.email ||
            !this.password ||
            !this.repassword ||
            !this.termsOfUse ||
            this.waiting
        );
    }

    public get identicalError(): string {
        if (
            this.password &&
            this.repassword &&
            this.password !== this.repassword
        ) {
            return 'Passwords must be identical';
        }
        return '';
    }

    protected showTermsOfUse() {
        this.$root.$emit('bv::show::modal', 'EulaModal');
    }

    // methods
    protected async register() {
        const data = {
            forename: this.forename,
            surname: this.surname,
            email: this.email,
            organization: this.organization,
            password: this.password,
        } as NewUserRequestDTO;
        this.waiting = true;

        try {
            const userInfo = await this.sessionService.register(data);

            // this causes
            // vue-router.esm.js?8c4f:2008 Uncaught (in promise)
            // NavigationDuplicated: Avoided redundant navigation to current
            // location: "/".
            // Also no need, since login will be done from adtivation page.

            // router.push('/');
            // this.$router.push('/');

            this.$toasted.show(this.$tc('toasts.activationLink'), {
                type: 'info',
                position: 'top-right',
                duration: 9000, // was 7000
            });
        } catch (err) {
            this.errorMessage = this.errorService.getErrorMessage(
                err.response.data
            );
        } finally {
            this.waiting = false;

            // Close modal window,
            // But the login will be done from the activation page.
            (this.$refs.registerModalRef as any).hide();
        }
    }

}

