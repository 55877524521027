
























import { Component, Prop, Vue, Emit } from 'vue-property-decorator';
import { InterpretationRoi } from '@/models/text';

@Component({
    name: 'roi-layer',
    components: {},
})
export default class RoiLayer extends Vue {
    @Prop() public rois!: Iterator<InterpretationRoi>;
    @Prop({
        default: true,
    })
    public withClass!: boolean;

    public highlighted(roi: InterpretationRoi) {
        if (this.si) {
            return roi.signInterpretationId === this.si.signInterpretationId;
        }
    }
    public highlightedComment(roi: InterpretationRoi) {
        if (roi.signInterpretationId) {
            const si = this.$state.signInterpretations.get(
                roi.signInterpretationId
            );
            return (
                this.artefactEditorState.highlightCommentMode &&
                si &&
                (si.commentary || si.attributes.some((attr) => attr.commentary))
            );
        }
    }

    public isSelectedRoi(roi: InterpretationRoi) {
        return (
            this.selectedInterpretationRoi &&
            this.selectedInterpretationRoi.interpretationRoiId ===
                roi.interpretationRoiId
        );
    }

    public get selectedInterpretationRoi(): InterpretationRoi | null {
        return this.$state.artefactEditor.selectedInterpretationRoi;
    }

    private get si() {
        return this.$state.textFragmentEditor.singleSelectedSi;
    }

    public get artefactEditorState() {
        return this.$state.artefactEditor;
    }

    private onPathClicked(roi: InterpretationRoi) {
        this.roiClicked(roi);
    }

    @Emit()
    private roiClicked(roi: InterpretationRoi) {
        return roi;
    }
}
