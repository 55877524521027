export default {
    filter: "Filtre d'éditions",
    publicEditionGroupCount: "Pas d'éditions publiques | Une édition publique |  éditions publiques ({count})",
    personalEditionGroupCount: 'Aucune édition personnelle | Une édition personnelle | éditions personnelles ({count}) ',
    publicEditionCount: ' | édition publique | éditions publiques',
    personalVersionCount: ' | édition personnelle | éditions personnelles',
    shares: ' | partage | partages',
    artefacts: 'Artefacts',
    versions: 'Versions',
    columns: 'Collones',
    imagedObjects: 'Objets',
    copyTitle: 'Copie {name} par {owner}',
    newEditionName: "Nouveau nom d'édition",
    newEditionDesc: 'Veillez nommer la nouvelle édition',
    copyingEdition: 'Edition Copiée',
    noVersions: 'Aucune autre version',

    newArtefactName: "Nouveau nom de l'Artefact",
    newArtefact: 'Nouveau Artefact',
    creatingNewArtefact: 'Création du nouvel Artefact',
    undo: 'Annuler',
    redo: 'Refaire',
    filterArtefacts: "Filtre d'Artefacts",
    both: 'Recto et Verso',
    editionInfo: 'Edition  Info',
    editionSize: "Taille de l'édition",
    viewPortSize: "Taille d'affichage",
    lock: 'Lecture seulement',
    shared: 'Partagé',
    admin: "Vos droits d'administrateur ont été révoqués",
    search: 'Recherche'
};
