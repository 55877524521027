




















































import { Component, Prop, Emit, Vue } from 'vue-property-decorator';

@Component({
    name: 'Faq',
    components: {
    }
})

export default class FaqModal extends Vue {
}

