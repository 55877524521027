
















































































































import { Component, Prop, Emit, Vue } from 'vue-property-decorator';
import { EditionInfo } from '@/models/edition';
import EditionService from '@/services/edition';
import { ImagedObject } from '@/models/imaged-object';
import PermissionModal from './permission-modal.vue';
import EditionIcons from '@/components/cues/edition-icons.vue';


@Component({
    name: 'edition-ver-sidebar',
    components: {
        PermissionModal,
        EditionIcons
    }
})

export default class SideBar extends Vue {

    @Prop() private page!: string;


    private editionId: number = 0;
    private editionService: EditionService = new EditionService() ;
    private newCopyName: string = '';
    private waiting: boolean = false;
    private errorMessage: string =  '';
    private newEditionName: string =  '';
    private renaming: boolean = false;

    public get currentEdition(): EditionInfo | null {
        return this.$state.editions.current ;
    }


    public get readOnly(): boolean {
        return this.currentEdition!.permission.readOnly;
    }

    public get isAdmin(): boolean {
        return this.currentEdition!.permission.isAdmin;
    }

    public get canRename(): boolean {
        return this.currentEdition!.permission.mayWrite;
    }

    public get user(): boolean {
        return this.$state.session.user ? true : false;
    }

    public get canCopy(): boolean {
        return this.newCopyName.trim().length > 0;
    }


    public get isNew(): boolean {
        if (this.currentEdition) {
            return this.currentEdition.id === this.$state.misc.newEditionId;
        }
        return false;
    }

    public get imagedObjects(): number {
        if (this.$state.imagedObjects.items) {
            return this.$state.imagedObjects.items.length;
        }
        return 0;
    }

    public get artefacts(): number {
        if (this.$state.imagedObjects.items) {
            let artLen = 0;
            this.$state.imagedObjects.items.forEach(
                (element: ImagedObject) => {
                    artLen += element.artefacts.length;
                }
            );
            return artLen;
        }
        return 0;
    }


    private  openPermissionModal() {
        this.$root.$emit('bv::show::modal', 'permissionModal');
    }

    private openRename() {
        this.renaming = true;
        this.newEditionName = this.currentEdition!.name;
    }

    private showMessage(msg: string, type: string = 'info') {
        this.$toasted.show(this.$tc(msg), {
            type,
            position: 'top-right',
            duration: 7000
        });
    }

    private versionString(ver: EditionInfo) {
        return ver.name;
    }

    private async copyEdition(evt: Event) {
        evt.preventDefault();

        if (!this.canCopy) {
            return; // ENTER key calls this handler even if the button is disabled
        }
        this.newCopyName = this.newCopyName.trim();

        this.waiting = true;
        this.errorMessage = '';
        try {
            const newEdition = await this.editionService.copyEdition(
                this.currentEdition!.id,
                this.newCopyName
            );

            this.$state.misc.newEditionId = newEdition.id;

            this.$router.push({
                path: `/editions/${newEdition.id}`
            });
            (this.$refs.copyModalRef as any).hide();
        } catch (err) {
            this.errorMessage = err;
        } finally {
            this.waiting = false;
        }
    }

    private copyModalShown() {
        this.newCopyName = this.currentEdition!.name;
        (this.$refs.newCopyName as any).focus();
    }

    private async onRename(newName: string) {
        if (!this.currentEdition) {
            throw new Error("Can't rename if there is no edition");
        }
        this.renaming = true;
        try {
            await this.editionService.renameEdition(
                this.currentEdition!.id,
                newName
            );
            this.showMessage('toasts.editionSuccess', 'success');
        } catch (err) {
            this.showMessage('toasts.editionError', 'error');
        } finally {
            this.renaming = false;
        }
    }

}

