import { render, staticRenderFns } from "./artefact-svg.vue?vue&type=template&id=64b5f752&scoped=true&"
import script from "./artefact-svg.vue?vue&type=script&lang=ts&"
export * from "./artefact-svg.vue?vue&type=script&lang=ts&"
import style0 from "./artefact-svg.vue?vue&type=style&index=0&id=64b5f752&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64b5f752",
  null
  
)

export default component.exports