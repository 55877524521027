
























import { Component, Prop, Mixins } from 'vue-property-decorator';
import { IIIFImage } from '@/models/image';
import ArtefactDataMixin from './artefact-data-mixin';

@Component({
    name: 'artefact-svg',
})
export default class ArtefactSvg extends  Mixins(ArtefactDataMixin) {
    @Prop({default: 1.3}) private aspectRatio!: number;

    private elementWidth = 0;
    private serverScale = 5;
    private loaded = false;

    get scale(): number {
        if (this.elementWidth && this.masterImageManifest) {
            return this.elementWidth / this.boundingBox.width;
        }

        return 0.05;
    }

    get elementHeight(): number {
        if (this.elementWidth) {
            return this.elementWidth / this.aspectRatio;
        }

        return 100;
    }

    protected async mounted() {
        await this.mountedDone;
        this.loaded = true;
        this.updateWidth();
        window.addEventListener('resize', () => {
            this.updateWidth();
        });
    }

    private updateWidth() {
        this.elementWidth = this.$el.clientWidth;
        if (!this.loaded) {
            // This should never happen
            console.warn('updateWidth called before data was loaded, which makes very little sense');
            this.serverScale = 5;
            return;
        }

        if (this.imageStack) {
            this.serverScale = this.imageStack!.master.getOptimizedScaleFactor(this.elementWidth,
                this.elementWidth / this.aspectRatio,
                this.boundingBox);
        }
    }
}

