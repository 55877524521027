

































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

// We use require because with imports webpack complains a lot.
// tslint:disable-next-line
const ClassicEditor = require('@ckeditor/ckeditor5-build-classic');

@Component({
    name: 'comment',
    components: {
    },
})
export default class CommentComponent extends Vue {
    // Follow the v-model pattern as described here: https://www.digitalocean.com/community/tutorials/vuejs-add-v-model-support
    @Prop()
    public value!: string;

    private comment: string = ''; // Use v-model to bind here, we can't use v-model to bind to the value property
    private editor = ClassicEditor;

    private get readOnly(): boolean {
        return this.$state.editions.current!.permission.readOnly;
    }

    private get commentDisplay() {
        if (!this.comment) {
            return 'None';
        }

        let shortened = this.comment.substring(0, 10);
        if (this.comment.length > 10) {
            shortened += '...';
        }
        return shortened;
    }

    private mounted() {
        this.comment = this.value || '';
    }

    private onCommentUpdated() {
        this.$emit('input', this.comment);
    }

    @Watch('value')
    private onValueChanged() {
        this.comment = this.value || '';
    }

    private onDeleteComment() {
        this.comment = '';
        this.onCommentUpdated();
    }

    private onViewComment() {
        (this.$refs.viewCommentModalRef as any).show();
        // this.$bvModal.show('viewCommentModal');
    }

    private onEditComment() {
        (this.$refs.editCommentModalRef as any).show();
        // this.$bvModal.show('editCommentModal');
    }
}
