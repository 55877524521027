











import { Component, Prop, Vue } from 'vue-property-decorator';
import { Artefact } from '@/models/artefact';

@Component({
    name: 'artefact-layer',
})
export default class ArtefactLayer extends Vue {
    @Prop() public readonly artefact!: Artefact;
    @Prop() public readonly color!: string;
    @Prop() public readonly editable!: boolean;
    @Prop() public readonly selected!: boolean;

    private get additionalStyle() {
        return `stroke: ${this.color}; fill: ${this.color}`;
    }

    private get polygon() {
        return this.artefact.mask;
    }
}
