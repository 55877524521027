



















































import { SearchBarValue } from '@/state/utilities';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';

// At some point we need to make those a little nicer
export interface SearchBarParams {
    filter?: boolean;
    side?: boolean;
    sort?: boolean;
}

@Component({
    name: 'search-bar',
    components: {},
})
export default class SearchBar extends Vue {
    @Prop({
        default: () => ({
            filter: false,
            view: false,
            sort: false,
        }),
    })
    public params!: SearchBarParams;

    @Prop()
    public value!: SearchBarValue;

    public internalValue: SearchBarValue = {};

    public mounted() {
        this.internalValue = {...this.value};
        this.onSearch();
    }

    public onFilterChange(inputEvent: string | undefined) {
        this.onSearch();
    }

    public onViewChange(viewEvent: string | undefined) {
        this.onSearch();
    }

    public onSortChange(selectEvent: string | undefined) {
        this.onSearch();
    }

    @Watch('value')
    private onValueParamChange(newValueParam: SearchBarValue) {
        this.internalValue = {...this.value};
    }

    @Emit('search')
    private onSearch() {
        this.internalValue = {...this.internalValue};
        return this.internalValue;
    }
}
