























































































import { Component, Prop, Vue, Emit } from 'vue-property-decorator';
import { Artefact } from '@/models/artefact';
import { TextFragment, ArtefactTextFragmentData } from '@/models/text';
import TextFragmentComponent from '@/components/text/text-fragment.vue';
import EditSignModal from '@/components/text/edit-sign-modal.vue';
import EditLineModal from '@/components/text/edit-line-modal.vue';
import AddLineModal from '@/components/text/add-line-modal.vue';
import DeleteLineModal from '@/components/text/delete-line-modal.vue';
import { ArtefactEditorMode } from './types';

@Component({
    name: 'text-side',
    components: {
        'text-fragment': TextFragmentComponent,
        'edit-sign-modal': EditSignModal,
        'edit-line-modal': EditLineModal,
        'add-line-modal': AddLineModal,
        'delete-line-modal': DeleteLineModal,
    },
})
export default class TextSide extends Vue {
    @Prop() public artefact!: Artefact;
    @Prop({ default: 'artefact'})
    public editorMode!: ArtefactEditorMode;
    @Prop() public textFragment!: TextFragment;
    @Prop() public fontSize!: number;

    private get artefactMode() {
        return this.editorMode === 'artefact';
    }

    private get textFragmentMode() {
        return this.editorMode === 'text-fragment';
    }

    // @Prop() public selectedSignInterpretation!: SignInterpretation | null;
    private errorMessage = '';
    private loading = false;
    private textFragmentId = 0;

    private displayedTextFragments: TextFragment[] = []; // Text fragments that are going to be displayed
    private displayedTextFragmentsShow: { [key: number]: boolean } = {}; // Map - form text fragment id to boolean
    private get editionId(): number {
        return parseInt(this.$route.params.editionId);
    }

    private get readOnly(): boolean {
        return this.$state.editions.current!.permission.readOnly;
    }

    private get dropdownTextFragmentsData() {
        const displayedTfIds = this.displayedTextFragments.map((tf) => tf.id);
        return this.allTextFragmentsData.filter(
            (x) => (!x.certain || !x.suggested)  && !displayedTfIds.includes(x.id)
        );
    }

    private get displayedTextFragmentsData() {
        // Try first to get 'certain' matches,
        // if there are none, then fall back to 'suggested' (which might also be none)
        const certain = this.allTextFragmentsData.filter((x) => x.certain);
        console.info(this.allTextFragmentsData.filter((x) => x.suggested));
        return certain.length > 0 ? certain : this.allTextFragmentsData.filter((x) => x.suggested);
    }

    private get openedTextFragement() {
        if (this.$state.textFragmentEditor.singleSelectedSi) {
            const tfId = this.$state.textFragmentEditor.singleSelectedSi.sign.line
                .textFragment.textFragmentId;
            return this.displayedTextFragments.findIndex(
                (tf) => tf.id === tfId
            );
        }
        return 0;
    }

    private isTfShown(tfId: number) {
        return this.displayedTextFragmentsShow[tfId];
    }

    private get allTextFragmentsData() {
        let textFragments = this.$state.editions.current!.textFragments.map(
            (tf) => ArtefactTextFragmentData.createFromEditionTextFragment(tf)
        );
        const textFragmentsArtefact =
            this.$state.artefacts.current!.textFragments || [];

        textFragments = textFragments.map((editionTf) => {
            for (const artefactTf of textFragmentsArtefact) {
                if (artefactTf.id === editionTf.id) {
                    editionTf.suggested = editionTf.suggested || artefactTf.suggested;
                    editionTf.certain = editionTf.certain || artefactTf.certain;
                }
            }
            return editionTf;
        });
        // textFragments = textFragments.map((editionTf) => {
        //     // Copy the suggested and certain attributes for TFs matching the artefactId
        //     textFragmentsArtefact.forEach((artefactTf) => {
        //         if (artefactTf.id === editionTf.id) {
        //             editionTf.suggested = artefactTf.suggested;
        //             editionTf.certain = artefactTf.certain;
        //         }
        //     });

        //     return editionTf;
        // });

        return textFragments;
    }

    private async mounted() {
        await this.$state.prepare.artefact(this.editionId, this.artefact.id);
        if (this.textFragmentMode) {
            this.displayedTextFragments = [this.textFragment];
            this.displayedTextFragmentsShow[this.textFragment.id] = true;
        } else {
            this.displayedTextFragmentsData.forEach(async (tfd, idx) => {
                await this.getFragmentText(tfd.id);
                const tf = this.$state.textFragments.get(tfd.id);
                if (tf) {
                    this.displayedTextFragments.push(tf);
                    this.displayedTextFragmentsShow[tfd.id] =
                        idx === 0 ? true : false;
                }
            });
        }
    }

    private async loadFragment(event: Event) {
        const target = event.target as HTMLInputElement;
        this.errorMessage = '';
        const textFragmentData = this.allTextFragmentsData.find(
            (obj) => obj.name === target.value
        );
        if (target.value) {
            if (!textFragmentData) {
                this.errorMessage = 'This fragment does not exist';
                return;
            }

            const tfIdsToDelete = this.allTextFragmentsData
                .filter((tfData) => !tfData.certain)
                .map((t) => t.id);

            this.displayedTextFragments = this.displayedTextFragments.filter((x, index) => {
                return !tfIdsToDelete.includes(x.id);
            });

            await this.getFragmentText(textFragmentData.id);
            const tf = this.$state.textFragments.get(textFragmentData.id);

            if (tf) {
                this.displayedTextFragments = [
                    tf,
                    ...this.displayedTextFragments,
                ];
            }
            this.emptySelectedState();
        }
    }
    private emptySelectedState(id?: number) {
        if (
            id &&
            this.$state.textFragmentEditor.singleSelectedSi &&
            this.$state.textFragmentEditor.singleSelectedSi.sign.line.textFragment
                .textFragmentId === id
        ) {
            return;
        }
        this.$state.textFragmentEditor.selectedSignInterpretations = [];
        this.$state.artefactEditor.selectRoi(null);
    }
    private changePosition(index: number, up: boolean) {
        const indexToChange = up ? index - 1 : index + 1;
        const isInBoudaries = up
            ? indexToChange >= 0
            : indexToChange < this.displayedTextFragments.length;
        if (isInBoudaries) {
            const temp = this.displayedTextFragments[index];
            this.displayedTextFragments[index] = this.displayedTextFragments[
                indexToChange
            ];
            this.displayedTextFragments[indexToChange] = temp;
            this.displayedTextFragments = [...this.displayedTextFragments];
        }
    }

    private async getFragmentText(textFragmentId: number) {
        this.loading = true;
        await this.$state.prepare.textFragment(this.editionId, textFragmentId);
        this.loading = false;
        // this.textFragmentSelected(this.textFragmentId);
    }

    @Emit()
    private textFragmentSelected(textFragmentId: number) {
        return textFragmentId;
    }

    @Emit()
    private textFragmentsLoaded() {
        // Let the artefact editor know we've loaded all the initial ROIs
    }
}
