





























import { Component, Prop, Vue } from 'vue-property-decorator';
import { EditionInfo } from '@/models/edition';
import EditionIcons from '@/components/cues/edition-icons.vue';
import Waiting from '@/components/misc/Waiting.vue';
import EditionCard from './edition-card.vue';
import CopyEditionModal from './copy-edition-modal.vue';

@Component({
    name: 'editions-list',
    components: {
        Waiting,
        EditionCard,
        // CopyPersonalEditionModal
        CopyEditionModal,
    },
})
export default class EditionsList extends Vue {
    @Prop() public title!: string;
    @Prop() public editions!: EditionInfo[];

    private openCopyEditionModal(edition: EditionInfo) {
        this.$state.editions.current = edition;

        // this.$root.$emit('bv::show::modal', 'copy-edition-modal');

        // BootstrapVue recomends to use this method:
        // this.$bvModal.show('copy-edition-modal');
        this.$root.$bvModal.show('copy-edition-modal');
    }
}
