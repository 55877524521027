





import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    name: 'toolbar',
})
export default class Toolbar extends Vue {
    @Prop({default: false}) public noGutters!: boolean;

}
