


































































import { Component, Prop, Emit, Vue } from 'vue-property-decorator';
import { EditionInfo } from '@/models/edition';
import EditionIcons from '@/components/cues/edition-icons.vue';

@Component({
    name: 'edition-card',
    components: { EditionIcons },
})
export default class EditionCard extends Vue {
    @Prop() public edition!: EditionInfo;

    private get thumbnailSource(): string | undefined {
        return this.edition?.thumbnail?.thumbnailUrl;
    }

    private editionEditClick() {
        this.$router.push({ path: `/editions/${this.edition.id}` });
    }

    private editionEditRightClick() {
        const editionLink = this.$router.resolve({ path: `/editions/${this.edition.id}` });
        window.open(editionLink.href);
    }

    public get lastEditText() {
        function date2str(dt: Date) {
            return dt.toISOString().split('T')[0];
        }

        if (!this.edition.lastEdit) {
            return '';
        }

        const today = new Date();
        const yesterday = new Date();
        yesterday.setDate(today.getDate() - 1);

        if (today.getDate() === this.edition.lastEdit.getDate()) {
            return 'Today';
        } else if (yesterday.getDate() === this.edition.lastEdit.getDate()) {
            return 'Yesterday';
        }

        return date2str(this.edition.lastEdit);
    }

    @Emit()
    private editionCopyClick() {
        return true;
    }
}
