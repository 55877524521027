



















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    name: 'corrupted-state-dialog',
})
export default class CorruptedStateDialog extends Vue {
    public reloadPage() {
        this.$router.go(0);
        (this.$refs.corruptedModalRef as any).hide();
    }
}
