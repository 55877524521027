

























import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { EditionInfo } from '@/models/edition';
import { SearchBarParams } from '@/components/search-bar.vue';
import CopyEditionModal from './copy-edition-modal.vue';
import Waiting from '@/components/misc/Waiting.vue';
import SearchBar from '@/components/search-bar.vue';
import EditionsPublicList from './edition-public-list.vue';
import { SearchBarValue } from '@/state/utilities';

@Component({
    name: 'public-editions',
    components: {
        Waiting,
        SearchBar,
        EditionsPublicList,
        CopyEditionModal,
    },
})
export default class PublicEditions extends Vue {
    public filteredEditions: EditionInfo[] = [];
    public searchBarParams: SearchBarParams = {
        filter: true,
        sort: true,
        side: false,
    };

    public get searchValue() {
        return this.$state.misc.editionSearchBarValue;
    }

    public set searchValue(newVal: SearchBarValue) {
        this.$state.misc.editionSearchBarValue = newVal;
    }

    @Watch('$state.misc.editionSearchBarValue', {deep: true})
    public onSearchValueChanged() {
        this.onPublicEditionsLoad();
    }

    public onEditionsSearch(newSearch: SearchBarValue) {
        this.searchValue = newSearch;
    }

    @Emit()
    public onPublicEditionsLoad() {
        this.filteredEditions = this.getFilteredEditions();
        return this.filteredEditions.length;
    }

    protected async mounted() {
        await this.$state.prepare.allEditions();
        this.onPublicEditionsLoad();
    }

    private getFilteredEditions(): EditionInfo[] {
        // This function is not really efficient, but it does work quickly enough for the editions we have.
        // No need in optimizing it.

        return this.$state.editions.items
            .filter((ed: EditionInfo) => {
                let filter: boolean = ed.isPublic === true;
                // if (this.searchValue.view) {
                //     filter = filter && art.side === this.searchValue.view
                // }
                if (this.searchValue.filter) {
                    filter =
                        filter &&
                        ed.name
                            .toLowerCase()
                            .includes(this.searchValue.filter.toLowerCase());
                }
                return filter;
            })
            .sort((a: EditionInfo, b: EditionInfo) => {

                if (this.searchValue.sort) {
                    let aVal = (a as any)[this.searchValue.sort];
                    let bVal = (b as any)[this.searchValue.sort];

                    if ( 'name' === this.searchValue.sort ) {
                        return aVal.localeCompare( bVal ,   undefined,
                            { numeric: true, sensitivity: 'base' }
                        );

                    } else if ( 'lastEdit' === this.searchValue.sort ) {

                       // for undefined dates, take 01/01/1970 as default
                        if ( undefined === aVal ) {
                            aVal = new Date(1970, 1, 1, 1, 1, 1);
                        }
                        if ( undefined === bVal ) {
                            bVal = new Date(1970, 1, 1, 1, 1, 1);
                        }
                        return ((aVal > bVal) ? -1 :  1 );

                    } else {
                        return ((aVal > bVal) ? 1 : -1 );
                    }

                } else {
                    return 1;
                }
            });
    }
}
