









































import { Component, Prop, Vue, } from 'vue-property-decorator';
import { Artefact } from '@/models/artefact';
import { ArtefactTransformer } from '@/utils/artefact-transformer';

@Component({
    name: 'artefact-sillhouette'
})
export default class ArtefactSillouhette extends Vue {
    @Prop()
    public artefact!: Artefact;

    private transformer: ArtefactTransformer | null = null;

    public created() {
        this.transformer = new ArtefactTransformer(this.artefact);
    }

   private get inViewport(): boolean {
        return this.artefact.inViewport ;

   }
}
