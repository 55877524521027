import { render, staticRenderFns } from "./imaged-object-card.vue?vue&type=template&id=60f3216f&scoped=true&"
import script from "./imaged-object-card.vue?vue&type=script&lang=ts&"
export * from "./imaged-object-card.vue?vue&type=script&lang=ts&"
import style0 from "./imaged-object-card.vue?vue&type=style&index=0&id=60f3216f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60f3216f",
  null
  
)

export default component.exports