








































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    name: 'scroll-ruler',
})
export default class ScrollRuler extends Vue {
    @Prop({
        default: 0,
    })
    public horizontalTicks!: number;

    @Prop({
        default: 0,
    }) public verticalTicks!: number;
    @Prop({
        default: 1,
    }) public ppm!: number;
    @Prop({
        default: 1,
    })public zoom!: number;
    @Prop({
        default: 1,
    })public width!: number;
    @Prop({
        default: 1,
    })  public height!: number;

    public get horizontalTicksArray() {
        return Array.from(Array(this.horizontalTicks).keys());
    }

    public get verticalTicksArray() {
        return Array.from(Array(this.verticalTicks).keys());
    }
}
