

























































import { Component, Prop, Emit, Vue } from 'vue-property-decorator';

import SessionService from '@/services/session';
import ErrorService from '@/services/error';
import { ResetLoggedInUserPasswordRequestDTO } from '@/dtos/sqe-dtos';
import router from '@/router';

@Component({
    name: 'change-password',
})
export default class ChangePassword extends Vue {
    // data

    protected currentPassword: string = '';
    protected newPassword: string = '';
    protected rePassword: string = '';
    protected errorMessage: string = '';
    protected sessionService: SessionService = new SessionService();
    protected errorService: ErrorService = new ErrorService(this);
    protected waiting: boolean = false;

    // computed
    public get disableChange(): boolean {
        return (
            this.newPassword !== this.rePassword ||
            !this.currentPassword ||
            !this.newPassword ||
            !this.rePassword ||
            this.waiting
        );
    }

    public get identicalError(): string {
        if (
            this.newPassword &&
            this.rePassword &&
            this.newPassword !== this.rePassword
        ) {
            return 'Passwords must be identical';
        }
        return '';
    }

    // methods
    protected async change() {
        const data = {
            oldPassword: this.currentPassword,
            newPassword: this.newPassword,
        } as ResetLoggedInUserPasswordRequestDTO;
        this.waiting = true;

        try {
            await this.sessionService.changePassword(data);
            router.push('/');
            this.$toasted.show(this.$tc('toasts.passwordChanged'), {
                type: 'info',
                position: 'top-right',
                duration: 7000,
            });
        } catch (err) {
            this.errorMessage = this.errorService.getErrorMessage(
                err.response.data
            );
        } finally {
            this.waiting = false;
        }
    }
}
