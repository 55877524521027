/* File taken from the Scrollery Editor website
 *
 * File made Typescript friendly by setting all types to any and fixing minor issues
 * Svg objects where set to SVG type (which is a string for now)
 */

// tslint:disable:no-conditional-assignment

/*
 * This function transforms a well-known-text
 * polygon into an SVG path.  When a boundingBox
 * is passed, then we translate every point based
 * on the x and y position of that bounding box.
 * Otherwise, we just add each point to the string
 * unaltered.
 */
export function wktPolygonToSvg(wkt: string , boundingRect?: any) {
  let svg = '';
  if (!wkt || wkt === '') {
    return '';
  }

  // Sometimes wkt polygons have some spaces in them, which we need to remove.
  // TODO: Switch to jsts for all polygon handling, instead of this code
  wkt = wkt.replace('POLYGON ((', 'POLYGON((');  // Trailing space after POLYGON
  wkt = wkt.replace(/,\s/g, ','); // Space between numbers

  if (wkt.substring(0, 9) === 'POLYGON((') {
    const polygonInitRegex = /POLYGON/g;
    const parenInitRegex = /\(/g;
    const parenEndRegex = /\)/g;
    svg = '';
    const polygons = wkt.split('),(');
    /**
     * This can be sped up by multithreading the parsing of each polygon,
     * and also by building the string in a more modular fashion
     * so that we don't neet to check if (svg.slice(-1) !== 'M') for
     * every point in the polygon (i.e..
     */
    polygons.forEach((polygon: any) => {
      svg += 'M';
      let currentPolygonSVG = '';
      polygon = polygon.replace(polygonInitRegex, '');
      polygon = polygon.replace(parenInitRegex, '');
      polygon = polygon.replace(parenEndRegex, '');
      const points = polygon.split(',');
      const length = points.length;
      let firstPoint;

      if (boundingRect) {
        firstPoint = `${points[0].split(' ')[0] - boundingRect.x} ${points[0].split(' ')[1] -
          boundingRect.y}`;
        for (let i = 0, point; (point = points[i]); i++) {
          currentPolygonSVG += 'L';
          currentPolygonSVG += `${point.split(' ')[0] - boundingRect.x} ${point.split(' ')[1] -
            boundingRect.y}`;
          if (i === length - 1) {
            if (
              `${point.split(' ')[0] - boundingRect.x} ${point.split(' ')[1] - boundingRect.y}` !==
              firstPoint
            ) {
              currentPolygonSVG += `L${firstPoint}`;
            }
          }
        }
      } else {
        firstPoint = `${points[0].split(' ')[0]} ${points[0].split(' ')[1]}`;
        for (let i = 0, point; (point = points[i]); i++) {
          currentPolygonSVG += 'L';
          currentPolygonSVG += `${point.split(' ')[0]} ${point.split(' ')[1]}`;
          if (i === length - 1) {
            if (`${point.split(' ')[0]} ${point.split(' ')[1]}` !== firstPoint) {
              currentPolygonSVG += `L${firstPoint}`;
            }
          }
        }
      }

      // Delete the first L from the string and add it to the svg variable
      svg += currentPolygonSVG.substring(1);
    });
  }

  return svg;
}

/*
 * This function transforms a well-known-text
 * point into an SVG path.
 */
export function wktPointToSvg(geoJSON: any) {
  return geoJSON.substring(0, 6) === 'POINT('
    ? {
        x: parseFloat(geoJSON.split(' ')[0].replace('POINT(', '')),
        y: parseFloat(geoJSON.split(' ')[1]),
      }
    : undefined;
}

/*
 * This function receives a well-known-text
 * representation of a rectangle and parses
 * that to a JSON object with an x-origin,
 * y-origin, width, and hieght.
 */
export function wktParseRect(wkt: any) {
  let returnRect: any;
  if (wkt.substring(0, 9) === 'POLYGON((') {
    const rect = wkt.replace('POLYGON((', '');
    const coords = rect.split(',');
    const x = parseInt(coords[0].split(' ')[0]);
    const y = parseInt(coords[0].split(' ')[1]);
    const width = parseInt(coords[2].split(' ')[0]) - x;
    const height = parseInt(coords[2].split(' ')[1]) - y;
    returnRect = { x, y, width, height };
  }
  return returnRect;
}

/*
 * This function receives an SVG path and
 * converts it to a WKT Polygon.
 */
export function svgPolygonToWKT(svg: string) {
  if (!svg || svg === '') {
    return '';
  }
  let wkt: string = '';
  svg = svg.trim();
  if (svg.startsWith('M')) {
    const lineSegmentRegex = /\sL\s|L|L\s|\sL/g;
    const zTerminatorRegex = /Z/g;
    wkt = 'POLYGON(';
    const polygons = svg.split('M');
    polygons.forEach((poly: any) => {
      if (poly) {
        if (wkt === 'POLYGON(') {
          wkt += '(';
        } else {
          wkt += '),(';
        }
        let firstPoint;
        let points;
        const lines = poly
          .replace(lineSegmentRegex, ' ')
          .replace(zTerminatorRegex, '')
          .trim();
        points = lines.split(' ');
        firstPoint = points[0] + ' ' + points[1];
        for (let i = 0, length = points.length - 1; i <= length; i += 2) {
          wkt += points[i] + ' ' + points[i + 1];
          if (i + 2 > length) {
            if (points[i] + ' ' + points[i + 1] !== firstPoint) {
              wkt += ',' + firstPoint;
            }
          } else {
            wkt += ',';
          }
        }
      }
    });
    wkt += '))';
  }
  return wkt;
}

/*
 * This function receives an SVG path and
 * converts it to a GeoJSON Polygon.
 */
export function svgPolygonToGeoJSON(svg: string) {
  if (!svg || svg === '') {
    return '';
  }
  let json: any;
  svg = svg.trim();
  if (svg.startsWith('M')) {
    json = { type: 'Polygon', coordinates: [] };
    const lineSegmentRegex = /\sL\s|L|L\s|\sL/g;
    const zTerminatorRegex = /Z/g;
    const polygons = svg.split('M');
    polygons.forEach((poly: any, index: number) => {
      if (poly) {
        json.coordinates.push([]);
        const points = poly
          .replace(lineSegmentRegex, ' ')
          .replace(zTerminatorRegex, '')
          .trim()
          .split(' ');
        const length = points.length;
        const firstPoint = [Number(points[0]), Number(points[1])];
        for (let i = 0, point1, point2; (point1 = points[i]) && (point2 = points[i + 1]); i += 2) {
          json.coordinates[index - 1].push([Number(point1), Number(point2)]);
          if (i + 2 === length) {
            if (Number(point1) !== firstPoint[0] || Number(point2) !== firstPoint[1]) {
              json.coordinates[index - 1].push(firstPoint);
            }
          }
        }
      }
    });
  }
  return json;
}

/*
 * This function receives an SVG path and
 * converts it to a Clipper Polygon.
 */
export function svgPolygonToClipper(svg: string) {
  if (!svg || svg === '') {
    return '';
  }
  let clipper: any;
  svg = svg.trim();
  if (svg.startsWith('M')) {
    clipper = [];
    const lineSegmentRegex = /\sL\s|L|L\s|\sL/g;
    const zTerminatorRegex = /Z/g;
    const polygons = svg.split('M');
    polygons.forEach((poly: any, index: number) => {
      if (poly) {
        clipper.push([]);
        let firstPoint;
        let points;
        const lines = poly
          .replace(lineSegmentRegex, ' ')
          .replace(zTerminatorRegex, '')
          .trim();
        points = lines.split(' ');
        firstPoint = { X: Number(points[0]), Y: Number(points[1]) };
        for (let i = 0, length = points.length - 1; i <= length; i += 2) {
          clipper[index - 1].push({ X: Number(points[i]), Y: Number(points[i + 1]) });
          if (i + 2 > length) {
            if (Number(points[i]) !== firstPoint.X || Number(points[i + 1]) !== firstPoint.Y) {
              clipper[index - 1].push(firstPoint);
            }
          }
        }
      }
    });
  }
  return clipper;
}

export function clipperToSVGPolygon(paths: any) {
  let svgPath: any;
  if (paths.constructor === Array && paths[0] && paths[0][0]) {
    svgPath = '';
    for (let i = 0, lengthI = paths.length; i < lengthI; i++) {
      const firstPoint = paths[i][0].X + ' ' + paths[i][0].Y;
      svgPath += 'M';
      let currentPolygonSVG = '';
      for (let j = 0, lengthJ = paths[i].length; j < lengthJ; j++) {
        currentPolygonSVG += 'L';
        currentPolygonSVG += paths[i][j].X + ' ' + paths[i][j].Y;
        if (j === lengthJ - 1) {
          if (paths[i][j].X + ' ' + paths[i][j].Y !== firstPoint) {
            currentPolygonSVG += 'L' + firstPoint;
          }
        }
      }
      svgPath += currentPolygonSVG.substring(1);
    }
  }

  return svgPath;
}

/*
 * This function receives an GeoJSON polygon and
 * converts it to a WKT Polygon.
 */
export function geoJSONPolygonToWKT(geoJSON: any) {
  let wkt: any;
  if (typeof geoJSON === 'string' && geoJSON.substring(0, 1) === '{') {
    geoJSON = JSON.parse(geoJSON.trim());
  }
  if (geoJSON.coordinates) {
    wkt = 'POLYGON(';
    for (let i = 0, poly; (poly = geoJSON.coordinates[i]); i++) {
      if (wkt === 'POLYGON(') {
        wkt += '(';
      } else {
        wkt += '),(';
      }
      const firstPoint = poly[0][0] + ' ' + poly[0][1];
      const lastElement = poly.length - 1;
      // tslint:disable-next-line:no-shadowed-variable
      for (let i = 0, point; (point = poly[i]); i++) {
        wkt += point[0] + ' ' + point[1];
        if (i === lastElement) {
          if (point[0] + ' ' + point[1] !== firstPoint) {
            wkt += ',' + firstPoint;
          }
        } else {
          wkt += ',';
        }
      }
    }
    wkt += '))';
  }
  return wkt;
}

/*
 * This function receives an HTML5 canvas
 * along with a svg path for the clipping mask
 * It then draws the svg path onto the canvas.
 */
export function clipCanvas(canvas: HTMLCanvasElement, svgClipPath: string, fillColor: string, scaleFactor: number) {
  const ctx = canvas.getContext('2d');
  if (!ctx) {
    throw new Error(`Can't get context from canvas ${canvas}`);
  }
  ctx.clearRect(0, 0, canvas.width, canvas.height);
  ctx.globalCompositeOperation = 'source-over';
  ctx.fillStyle = fillColor;
  const polygons = svgClipPath.split('M').slice(1);
  ctx.beginPath();
  polygons.forEach((poly) => {
    const points = poly.split('L');
    for (let i = 0, length = points.length; i < length; i++) {
      const coordsStr = points[i].split(' ');
      const coords = coordsStr.map((s) => parseFloat(s) * scaleFactor);
      if (i === 0) {
        ctx.moveTo(coords[0], coords[1]);
      } else {
        ctx.lineTo(coords[0], coords[1]);
      }
    }
  });
  ctx.closePath();
  ctx.fill();
}
