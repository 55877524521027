






import { Component, Vue } from 'vue-property-decorator';

@Component({
//   name: 'home',
 name: 'waiting',
})

export default class Waiting extends Vue {}
