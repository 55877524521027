























import { Component, Prop, Emit, Model, Vue } from 'vue-property-decorator';
import TextFragmentComponent from '@/components/text/text-fragment.vue';
import SignAttributePane from '@/components/sign-attributes/sign-attribute-pane.vue';
import EditSignModal from '@/components/text/edit-sign-modal.vue';
import EditVirtualArtefactTextPane from '@/components/text/edit-virtual-artefact-text.vue';
import { VirtualArtefactEditor } from '@/services/virtual-artefact';

@Component({
    name: 'text-toolbar',
    components: {
        'text-fragment': TextFragmentComponent,
        'sign-attribute-pane': SignAttributePane,
        'edit-sign-modal': EditSignModal,
        'edit-virtual-artefact-text-pane': EditVirtualArtefactTextPane,

    },
})

export default class TextToolbar extends Vue {

    public get textFragment() {
        return this.$state.textFragmentEditor.selectedTextFragment;
    }

    private get showEditReconTextBar(): boolean {
        return this.$state.showEditReconTextBar;
    }

    protected onVirtualTextClose(param: { text: string, originalText: string, editor: VirtualArtefactEditor }) {
        this.$state.showEditReconTextBar = false;

        if (param.text !== param.originalText) {
            this.$emit('text-changed', { text: param.text, editor: param.editor });
        }
    }

}
