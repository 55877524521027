














































import { Component, Prop, Emit, Vue } from 'vue-property-decorator';

@Component({
    name: 'Citation',
    components: {
    }
})

export default class CitationModal extends Vue {
}

