






























import ArtefactImage from '@/components/artefact/artefact-image.vue';
import { ArtefactDTO, DetailedSearchRequestDTO, ExtendedArtefactDTO, ImagedObjectDTO, ImageDTO, ImageStackDTO } from '@/dtos/sqe-dtos';
import { Artefact } from '@/models/artefact';
import { EditionInfo } from '@/models/edition';
import { ImagedObject } from '@/models/imaged-object';
import SearchService from '@/services/search';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import EditionList from '../home/components/edition-list.vue';
import { SearchFormData, SearchResults } from './types';

interface ArtefactWithEdition {
    artefact: Artefact;
    imagedObject?: ImagedObject;
    edition: EditionInfo;
}

@Component({
    name: 'artefact-results',
    components: {
        'artefact-image': ArtefactImage,
    }
})
export default class ArtefactResultComponent extends Vue {
    @Prop( { default: null })
    private artefacts!: ExtendedArtefactDTO[] | null;
    private ready = false;

    private async mounted() {
        this.ready = false;
        await this.$state.prepare.allEditions();
        this.ready = true;
    }

    private createImagedObjectDTO(dto: ExtendedArtefactDTO) {
        // Create a DTO for the imaged object.
        // This DTO has a lot of default fields, we only need the id, recto and verso urls and ppi

        if (!dto.url) {
            console.warn('Search result returned an artefact with no image url', dto);
            return undefined;
        }

        // The url from the server is either for recto or verso - depending on the artefact's side.
        // An ImagedObject must have both recto and verso image stacks, so we just replicate the same stack
        // twice.
        const rectoImageDTO: ImageDTO = {
            id: -1,
            url: dto.url,
            lightingType: 'direct',
            lightingDirection: 'top',
            waveLength: [],
            type: 'master',
            side: 'recto',
            ppi: dto.ppi,
            master: true,
            catalogNumber: 0,
            imageManifest: '',
        };
        const rectoImageStackDTO: ImageStackDTO = {
            id: -1,
            images: [rectoImageDTO],
            masterIndex: 0,
        };

        const versoImageDTO = { ...rectoImageDTO };
        versoImageDTO.side = 'verso';
        const versoImageStackDTO: ImageStackDTO = {
            id: -1,
            images: [versoImageDTO],
            masterIndex: 0,
        };

        const imagedObjectDTO: ImagedObjectDTO = {
            id: dto.imagedObjectId,
            recto: rectoImageStackDTO,
            verso: versoImageStackDTO,
            artefacts: [],
        };

        return imagedObjectDTO;
    }

    private get artefactsWithEditions() {
        const artefacts: ArtefactWithEdition[] = [];

        if (!this.artefacts) {
            return artefacts;
        }

        for (const dto of this.artefacts) {
            const edition = this.$state.editions.find(dto.editionId);
            if (edition) {
                const artefact = new Artefact(dto);
                const imagedObjectDTO = this.createImagedObjectDTO(dto);
                const imagedObject = imagedObjectDTO ? new ImagedObject(imagedObjectDTO, edition) : undefined;
                artefacts.push( { artefact, edition, imagedObject });
            }
        }

        return artefacts;
    }

    private get title() {
        return `Artefacts (${this.artefactsWithEditions.length || 0})`;
    }
}
