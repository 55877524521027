











import { Component, Prop, Vue } from 'vue-property-decorator';

export type breakpoints = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
@Component({
    name: 'toolbox'
})
export default class Toolbox extends Vue {
    @Prop({ default: 'xs' }) public hideBelow!: string;
    @Prop({ default: '' }) public subject!: string;
    @Prop({ default: false }) public noMargins!: boolean;
    @Prop({ default: false}) public disabled!: boolean;

    public get classes() {
        let classes: string[] = [];
        if (this.hideBelow === 'xs') {
            classes = ['d-block'];
        } else {
            classes = ['d-none', `d-${this.hideBelow}-block`];
        }

        if (this.noMargins) {
            classes.push('no-margins');
        }

        return classes;
    }
}
