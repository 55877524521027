







import EditionMetadataModal from '@/views/edition/components/metadata.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Toolbox from './toolbox.vue';

@Component({
    name: 'edition-toolbox',
    components: {
        'edition-metadata-modal': EditionMetadataModal,
        toolbox: Toolbox
    },
})
export default class EditionToolbox extends Vue {
    private openMetadata() {
        console.debug('openMetadata called');
        this.$root.$emit('bv::show::modal', 'editionMetadataModal');
    }
}
