






























































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { localizedTexts } from '@/i18n';
import SessionService from '@/services/session';
import Login from './Login.vue';
import FaqModal from './Faq-modal.vue';
import EulaModal from './Eula-modal.vue';
import CitationModal from './CitationModal.vue';
// import ScreenSizeAlert from '../../views/home/components/ScreenSizeAlert.vue';
import router from '@/router';
import { EditionInfo } from '../../models/edition';
import { BIcon, BIconSearch, BIconPersonFill, BIconList } from 'bootstrap-vue';
import Registration from '@/views/user/Registration.vue';
import ToolbarIconButton from '../toolbars/toolbar-icon-button.vue';
import EditionToolbox from '../toolbars/edition-toolbox.vue';
import ReportProblemModal from './report-problem-modal.vue';

@Component({
    name: 'navbar',
    components: {
        login: Login,
        'faq-modal': FaqModal,
        'eula-modal': EulaModal,
        'citation-modal': CitationModal,
        'edition-toolbox': EditionToolbox,
        'report-problem-modal': ReportProblemModal,
        register: Registration,
        // 'screen-size-alert': ScreenSizeAlert,
        BIcon,
        BIconSearch,
        BIconPersonFill,
        BIconList,
    },
})
export default class Navbar extends Vue {
    private sessionService = new SessionService();
    private currentLanguage = 'en';
    private allTexts = localizedTexts;

    public get edition() {
        return this.$state.editions.current;
    }

    protected get operationsManager() {
        return this.$state.operationsManager;
    }

    protected get showOperationsManager() {
        return (
            !!this.operationsManager &&
            !!this.edition &&
            !this.edition.permission.readOnly
        );
    }

    protected onUndo() {
        this.operationsManager!.undo();
    }

    protected onRedo() {
        this.operationsManager!.redo();
    }

    public goHome() {
        this.$router.push({ path: '/' });
    }

    public goGuide() {
        window.open(
            'https://sway.office.com/oiGhObqnG1IODSgZ?ref=Link',
            '_blank'
        );
    }

    public goPrivate() {
        this.$router.push({ path: '/home/private' });
    }
    public goPublic() {
        this.$router.push({ path: '/home/public' });
    }

    public goAbout() {
        window.open(' https://www.qumranica.org/', '_blank');
    }

    public showFAQModal() {
        this.$root.$emit('bv::show::modal', 'FaqModal');
    }

    public reportProblemModal() {
        this.$root.$emit('bv::show::modal', 'ReportProblemModal');
    }

    public showEulaModal() {
        this.$root.$emit('bv::show::modal', 'EulaModal');
    }

    public showCitation() {
        this.$root.$emit('bv::show::modal', 'CitationModal');
    }

    public contactUs() {
        location.href = 'mailto:sqe@deadseascrolls.org.il';
    }

    public get editionBadgeClass() {
        if (!this.edition) {
            return '';
        }
        return this.edition.isPublic
            ? 'status-badge-published'
            : 'status-badge-draft';
    }

    public get editionBadge() {
        if (!this.edition) {
            return '';
        }

        return this.edition.isPublic ? 'Published' : 'Draft';
    }

    public get artefactLink() {
        if (this.$state.artefacts.current) {
            return `/editions/${this.edition!.id}/artefacts/${
                this.$state.artefacts.current.id
            }`;
        }
        return `/editions/${this.edition!.id}/artefacts/`;
    }

    public get artefactLabel() {
        return this.$state.artefacts.current ? 'Artefact' : 'Artefacts';
    }

    public get imagedObjectLink() {
        if (this.$state.imagedObjects.current) {
            return `/editions/${
                this.edition!.id
            }/imaged-objects/${encodeURIComponent(
                this.$state.imagedObjects.current.id
            )}`;
        }
        return `/editions/${this.edition!.id}/imaged-objects/`;
    }

    public get imagedObjectLabel() {
        return this.$state.imagedObjects.current
            ? 'Imaged Object'
            : 'Imaged Objects';
    }

    public get userNameExists(): boolean {
        return undefined !== this.userName;
    }

    public get userName(): string | undefined {
        if (this.$state.session.user) {
            return (
                this.$state.session.user.forename +
                ' ' +
                this.$state.session.user.surname
            );
        }
        return undefined;
    }

    public get isActive(): boolean {
        return this.$state.session.user
            ? this.$state.session.user.activated
            : false;
    }

    private get currentEdition(): EditionInfo | null {
        return this.$state.editions.current;
    }

    private changeLanguage(language: string) {
        this.$i18n.locale = language;
        this.$state.session.language = language;
        this.currentLanguage = language;

        // We can't implement currentLanguage as a computed property, because $state.session.language is a getter, and
        // Vue doesn't handle getter changes very well.
    }

    private mounted() {
        this.currentLanguage = this.$state.session.language;
    }

    public logout() {
        this.sessionService.logout();
        router.push('/');
        location.reload();
    }

    public login() {
        this.$root.$emit('bv::show::modal', 'loginModal');
    }

    public changePassword() {
        router.push('/changePassword');
    }

    public updateUserDetails() {
        router.push('/updateUserDetails');
    }
}
