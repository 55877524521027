




































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import Login from '../navigation/Login.vue';
import Registration from '@/views/user/Registration.vue';
import SessionService from '@/services/session';
import router from '@/router';
@Component({
    name: 'welcome',
})
export default class Welcome extends Vue {
    private sessionService = new SessionService();
    public login() {
        this.$root.$emit('bv::show::modal', 'loginModal');
    }
    public register() {
        this.$root.$emit('bv::show::modal', 'registerModal');
    }
    public get userName(): string | undefined {
        if (this.$state.session.user) {
            return (
                this.$state.session.user.forename +
                ' ' +
                this.$state.session.user.surname
            );
        }
        return undefined;
    }

    private startWorking() {
        router.push('/home');
    }

    private logout() {
        this.sessionService.logout();
        router.push('/');
        location.reload();
    }

    public get buildTime(): string {
        const buildTime = document.documentElement.dataset.buildTime;
        if (!buildTime) {
            return 'N/A';
        }

        return buildTime.substring(0, 10);
    }
}
