









import { Component, Prop, Emit, Vue } from 'vue-property-decorator';

import { ImagedObject } from '@/models/imaged-object';

@Component({
    name: 'imaged-object-card',
})

export default class ImagedObjectCard extends Vue {

     @Prop() private imagedObject!: ImagedObject;

    private get imageUrl(): string | undefined {
        // TS 3.7 and up , optional chaining returns undefined
        // if any chain member is null or undefined
         return this.imagedObject?.recto?.master?.getThumbnailUrl(600);
    }

    private get imageObjectId(): string  {
        // encodes characters such as ?,=,/,&,:
         return encodeURIComponent( this.imagedObject?.id) ;
    }

    private get editionId(): number | undefined {
        return this.$state?.editions?.current?.id;
    }

}

