







































import { Component, Prop, Emit, Vue } from 'vue-property-decorator';

import SessionService from '@/services/session';
import ErrorService from '@/services/error';
import router from '@/router';
import { ResetForgottenUserPasswordRequestDTO } from '@/dtos/sqe-dtos';

@Component({
     name: 'change-forgotten-password'
})

export default class ChangeForgottenPassword extends Vue {

  // data
  protected newPassword: string = '';
  protected rePassword: string = '';
  protected token: string = '';
  protected errorMessage: string = '';
  protected sessionService: SessionService = new SessionService();
  protected errorService: ErrorService = new ErrorService(this);
  protected waiting: boolean = false;

  // computed
  public get disableChange(): boolean {
        return this.newPassword !== this.rePassword
        || !this.newPassword || !this.rePassword || this.waiting;
  }

  public get identicalError(): string {
      if (this.newPassword && this.rePassword && this.newPassword !== this.rePassword) {
        return 'Passwords must be identical';
      }
      return '';
  }

  protected mounted() {
    const url  = window.location.href;
    this.token = url.split('token/')[1];
    if (this.token === '') {
      this.errorMessage = 'There is no token in url';
      console.error(this.errorMessage);
    }
  }

  // methods:
  public async change() {
      const data = {
        token: this.token,
        password: this.newPassword,
      } as ResetForgottenUserPasswordRequestDTO;
      this.waiting = true;
      try {
        await this.sessionService.changeForgottenPassword(data);
        router.push('/');
        this.$root.$emit('bv::show::modal', 'loginModal');
      } catch (e) {
        this.errorMessage = this.errorService.getErrorMessage(e.response.data);
      } finally {
        this.waiting = false;
      }
  }
}

