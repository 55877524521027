




















































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { EditionInfo, ShareInfo, SimplifiedPermission } from '@/models/edition';
import EditionService from '@/services/edition';

interface ShareRow {
    email: string;
    type: 'invitation' | 'share';
    oldPermission: SimplifiedPermission;
    permission: SimplifiedPermission;
    buttonText?: string;
    disableButton: boolean;
    disableRow: boolean;
}
@Component({
    name: 'permission-modal',
    components: {}
})
export default class PermissionModal extends Vue {
    public invitationRow: ShareRow = { permission: 'read' } as ShareRow;
    public editionService: EditionService = new EditionService();

    // public errorService:ErrorService= new ErrorService(this)
    public sharesRows: ShareRow[] = [];
    public invitationsRows: ShareRow[] = [];
    private waiting = false;
    private errorMessage = '';

    public async shown() {
        await this.editionService.stateManager.prepare.invitations(this.current.id);

        this.fillShareRows(this.current!.shares);
        this.fillInvitationRows(this.current!.invitations);
    }

    public get current(): EditionInfo {
        return this.$state.editions.current!;
    }

    public get isAdmin(): boolean {
        return this.current.permission.isAdmin;
    }

    public async update(share: ShareRow) {
        let msg = '';
        if (share.type === 'invitation') {
            await this.editionService.updateInvitation(
                this.current!.id,
                share.email,
                share.permission
            );
            msg = 'toasts.invitationSent';
        } else if (share.type === 'share') {
            await this.editionService.updateSharePermissions(this.current!.id, share.email, share.permission);
            msg = 'toasts.permissionsUpdated';
        }

        this.$toasted.show(this.$tc(msg, undefined, {email: share.email}), {
            type: 'info',
            position: 'top-right',
            duration: 5000,
        });
    }
    public async invite() {
        try {
            this.waiting = true;
            await this.editionService.inviteEditor(
                this.current!.id,
                this.invitationRow.email,
                this.invitationRow.permission
            );
        } catch (e) {
            // this.errorMessage = this.errorService.getErrorMessage(e);
            this.errorMessage = 'An error occured';
        } finally {
            this.waiting = false;
            this.invitationRow.email = '';
            this.invitationRow.permission = 'read';
        }
    }

    public setRowShareStatus(row: ShareRow) {
        if (row.email === this.$state.session.user!.email) {
            row.disableRow = true;
            return;
        }
        row.disableRow = false;
        if (row.permission !== row.oldPermission) {
            row.buttonText = 'Update';
            row.disableButton = false;
        } else {
            row.disableButton = true;
        }
        if (row.permission === 'none') {
            row.buttonText = 'Revoke';
        }
    }

    public setRowInvitStatus(row: ShareRow) {
        row.disableRow = false;
        if (row.permission !== row.oldPermission) {
            row.buttonText = 'Update';
        } else {
            row.buttonText = 'Resend';
        }
        if (row.permission === 'none') {
            row.buttonText = 'Revoke';
        }
    }

    private get invitationList() {
        return this.current.invitations;
    }

    @Watch('invitationList')
    private onInvitationsChange(newInvitations: ShareInfo[]) {
        this.fillInvitationRows(newInvitations);
    }

    private get shareList() {
        return this.current.shares;
    }

    @Watch('shareList')
    private onSharesChange(newShares: ShareInfo[]) {
        this.fillShareRows(newShares);
    }

    private fillInvitationRows(invitations: ShareInfo[]) {
        this.invitationsRows = this.current!.invitations.map(x => ({
            email: x.email,
            oldPermission: x.simplified,
            permission: x.simplified,
            buttonText: 'Resend',
            type: 'invitation',
            disableRow: false,
            disableButton: false,
        }));

        for (const row of this.invitationsRows) {
            this.setRowInvitStatus(row);
        }
    }

    private fillShareRows(shares: ShareInfo[]) {
        this.sharesRows = this.current!.shares.map(x => ({
            email: x.email,
            oldPermission: x.simplified,
            permission: x.simplified,
            buttonText: 'Update',
            disableRow: false,
            disableButton: false,
            type: 'share',
        }));

        for (const row of this.sharesRows) {
            this.setRowShareStatus(row);
        }
    }
}
