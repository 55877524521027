






































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { SignInterpretation } from '@/models/text';
import {
    AttributeDTO,
    AttributeValueDTO,
    InterpretationAttributeDTO,
} from '@/dtos/sqe-dtos';
import SignAttribute from './sign-attribute.vue';
import SignAttributeModal from './sign-attribute-modal.vue';
import { EditionInfo } from '@/models/edition';
import {
    SignInterpretationCommentOperation,
    TextFragmentAttributeOperation,
} from '@/views/artefact-editor/operations';
import { BDropdown, BvEvent } from 'bootstrap-vue';
import CommentComponent from '../comment/comment.vue';

@Component({
    name: 'sign-attribute-pane',
    components: {
        'sign-attribute-modal': SignAttributeModal,
        'sign-attribute': SignAttribute,
        comment: CommentComponent,
    },
})
export default class SignAttributePane extends Vue {
    private status: boolean = false;
    private keepOpen = false;
    private attributesMenu: AttributeDTO[] = [];

    private get readOnly(): boolean {
        return this.$state.editions.current!.permission.readOnly;
    }
    private get currentEdition(): EditionInfo | null {
        return this.$state.editions.current;
    }

    public get editorState() {
        return this.$state.textFragmentEditor;
    }

    public get selectedSignInterpretations(): SignInterpretation[] {
        return this.editorState.selectedSignInterpretations;
    }

    public get allSiAreReconstructed(): boolean {
        if ((this.editorState.selectedSignInterpretations.length === 0)) {
            return false;
        }
        return this.selectedSignInterpretations.every((si) =>
            si.attributes.some(
                (attr) =>
                    attr.attributeString === 'is_reconstructed' &&
                    attr.attributeValueString === 'TRUE'
            )
        );
    }

    // The comment in the state.
    private get comment(): string {
        if (this.selectedSignInterpretations.length !== 1) {
            return '';
        }

        return this.selectedSignInterpretations[0].commentary || '';
    }

    private set comment(val: string) {
        if (this.selectedSignInterpretations.length !== 1) {
            console.warn(
                "Can't change ta comment without one selected sign interperation"
            );
            return;
        }

        const op = new SignInterpretationCommentOperation(
            this.selectedSignInterpretations[0].id,
            val
        );
        op.redo(true);
        this.$state.eventBus.emit('new-operation', op);
    }

    private get attributesMetadata() {
        return (
            this.$state.editions.current?.attributeMetadata?.allAttributes || []
        );
    }

    public get attributes(): InterpretationAttributeDTO[] {
        // Get the common attributes from all the selected sign interpretations -
        // only attributes that appear in all sign interpretations are shown.
        let attributeValues: number[] = [];
        let first = true;

        if (!this.selectedSignInterpretations.length) {
            return [];
        }

        // Calculate the intersection of the attributes of all the sign interpretations
        for (const si of this.selectedSignInterpretations) {
            const siValues = si.attributes.map((attr) => attr.attributeValueId);
            if (first) {
                first = false;
                attributeValues = siValues;
            } else {
                attributeValues = attributeValues.filter((val) =>
                    siValues.includes(val)
                );
            }
        }

        // selectedSignInterpretations has at least one element
        const attributes =
            this.selectedSignInterpretations[0].attributes.filter((attr) =>
                attributeValues.includes(attr.attributeValueId)
            );
        return attributes;
    }

    private get isMultiSelect() {
        return (
            this.$state.textFragmentEditor.selectedSignInterpretations
                .length !== 1
        );
    }

    private onAttributeClick(attribute: InterpretationAttributeDTO) {
        this.$state.textFragmentEditor.selectedAttribute = attribute;
        this.$root.$emit('bv::show::modal', 'sign-attribute-modal');
    }

    private onAddAttribute(attr: AttributeDTO, attrVal: AttributeValueDTO) {
        const ops: TextFragmentAttributeOperation[] = [];
        for (const si of this.$state.textFragmentEditor
            .selectedSignInterpretations) {
            const op = new TextFragmentAttributeOperation(si.id, attrVal.id, {
                attributeId: attr.attributeId,
                attributeString: attr.attributeName,
                attributeValueId: attrVal.id,
                attributeValueString: attrVal.value,
            } as InterpretationAttributeDTO);
            op.redo(true);
            ops.push(op);
        }
        this.$state.eventBus.emit('new-bulk-operations', ops);
        this.keepOpen = false;
        (this.$refs.attributesMenu as BDropdown).hide();
    }

    private onDeleteAttribute(attrVal: AttributeValueDTO) {
        const ops: TextFragmentAttributeOperation[] = [];
        for (const si of this.$state.textFragmentEditor
            .selectedSignInterpretations) {
            const op = new TextFragmentAttributeOperation(
                si.id,
                attrVal.id,
                undefined
            );
            op.redo(true);
            ops.push(op);
        }
        this.$state.eventBus.emit('new-bulk-operations', ops);
    }

    private onReconstructedCheckBoxChanged(event: boolean) {
        let reconstructedAttrDTO: AttributeDTO;
        let reconstructedAttrValueDTO: AttributeValueDTO;
        const reconstructedAttrMeta = this.attributesMetadata.find(
            (a) => a.attributeName === 'is_reconstructed'
        );
        if (reconstructedAttrMeta) {
            reconstructedAttrDTO = { ...reconstructedAttrMeta };
            const reconstructedAttrValueMeta =
                reconstructedAttrDTO?.values.find((a) => a.value === 'TRUE');
            if (reconstructedAttrValueMeta) {
                reconstructedAttrValueDTO = { ...reconstructedAttrValueMeta };
            }
            if (event) {
                this.onAddAttribute(
                    reconstructedAttrDTO!,
                    reconstructedAttrValueDTO!
                );
            } else {
                this.onDeleteAttribute(reconstructedAttrValueDTO!);
            }
        }
    }

    private onAddAttributesMenuOpen() {
        this.attributesMenu = this.prepareAttributesMenu();
    }

    private prepareAttributesMenu(): AttributeDTO[] {
        if (!this.selectedSignInterpretations.length) {
            return [];
        }

        const filteredAttributes: AttributeDTO[] = [];
        const attributesSet: Set<number> = new Set<number>();
        const attributesValuesSet: Set<number> = new Set<number>();

        for (const si of this.selectedSignInterpretations) {
            for (const attribute of si.attributes) {
                attributesSet.add(attribute.attributeId);
                attributesValuesSet.add(attribute.attributeValueId);
            }
        }
        for (const attributeMeta of this.attributesMetadata) {
            const attributeCopy = { ...attributeMeta };
            // check repeatable
            if (
                attributesSet.has(attributeMeta.attributeId) &&
                !attributeMeta.repeatable
            ) {
                continue;
            }

            // multiple: only batchEditable
            if (
                this.selectedSignInterpretations.length > 1 &&
                !attributeMeta.batchEditable
            ) {
                continue;
            }

            // single: only editable
            if (
                this.selectedSignInterpretations.length === 1 &&
                !attributeMeta.editable
            ) {
                continue;
            }

            // repeatable: remove existing values
            if (
                attributesSet.has(attributeMeta.attributeId) &&
                attributeMeta.repeatable
            ) {
                for (const attributeValue of attributeMeta.values) {
                    if (attributesValuesSet.has(attributeValue.id)) {
                        attributeCopy.values = attributeCopy.values.filter(
                            (x) => x.id !== attributeValue.id
                        );
                    }
                }
            }

            filteredAttributes.push(attributeCopy);
        }

        return filteredAttributes;
    }

    private onValuesMenuShow() {
        this.keepOpen = true;
    }

    private onValuesMenuHide() {
        this.keepOpen = false;
        // (this.$refs.attributesMenu as BDropdown).hide();
    }

    private onAttributesMenuHide(event: BvEvent) {
        if (this.keepOpen) {
            event.preventDefault();
        }
    }
}
