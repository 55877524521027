











import { Component, Prop, Vue } from 'vue-property-decorator';
import { InterpretationAttributeDTO } from '@/dtos/sqe-dtos';

@Component({
    name: 'sign-attribute-badge',
})
export default class SignAttributeBadge extends Vue {
    @Prop()
    public attribute!: InterpretationAttributeDTO;

    public get artefactEditorState() {
        return this.$state.artefactEditor;
    }

    public get isHighlighted(): boolean {
        return (
            this.artefactEditorState.highlightCommentMode &&
            this.attribute &&
            !!this.attribute.commentary
        );
    }
}
