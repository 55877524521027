export default {
    brand: 'Scripta Qumranica Electronica',
    filter: 'Filter by',
    show: 'Show',
    search: 'Search',
    view: 'View',
    side: 'Side',
    sort: 'Sort by',
    publicEditionGroupCount: 'No public editions | One public edition |  Public Editions ({count})',
    personalEditionGroupCount: 'No personal editions | One personal edition |  Personal Editions ({count})',
    publicEditionCount: ' | public edition | public editions',
    personalVersionCount: ' | personal edition | personal editions',
    personalEditions: 'Personal Editions',
    publicEditions: 'Public Editions',
    userGuide: 'User Guide',
    shares: ' | share | shares',
    artefacts: 'Artefacts',
    scroll: 'Scroll',
    versions: 'Versions',
    columns: 'Columns',
    imagedObjects: 'Imaged Objects',
    copyTitle: 'Copy {name} by {owner}',
    newEditionName: 'New Edition Name',
    newEditionDesc: 'Please provide a name for the new edition',
    copyingEdition: 'Copying edition',
    noVersions: 'No other versions',

    newArtefactName: 'New Artefact Name',
    newArtefact: 'New Artefact',
    creatingNewArtefact: 'Creating New Artefact',
    undo: 'Undo',
    redo: 'Redo',
    filterArtefacts: 'Filter Artefacts',
    both: 'Both',
    editionInfo: 'Edition  Info',
    editionSize: 'Edition Size',
    viewPortSize: 'Viewport Size',
    lock: 'Read Only',
    shared: 'Shared',
    admin: 'Your Admin rights have been revoked',
    editorParameters: 'Editor Parameters',
    reportProblem: 'Report Problem'
};
