























import { Component, Prop, Mixins } from 'vue-property-decorator';
import { ImageSetting, SingleImageSetting } from '@/components/image-settings/types';
import ArtefactDataMixin from './artefact-data-mixin';
import ArtefactSvg from './artefact-svg.vue';
import IIIFImageComponent from '../images/IIIFImage.vue';

@Component({
    name: 'artefact-image',
    components: {
        'artefact-svg': ArtefactSvg,
        'iiif-image': IIIFImageComponent,
    },
})
export default class ArtefactImage extends Mixins(ArtefactDataMixin) {
    @Prop({default: 1.3}) private aspectRatio!: number;
    @Prop({
        default: () => {
            return {} as ImageSetting;
        }
    }) private imageSettings!: ImageSetting;
    @Prop({ default: 400 })
    private maxWidth!: number;

    private loaded = false;

    get visibleImageSettings(): SingleImageSetting[] {
        if (this.artefact.isVirtual) {
            return [];
        }

        if (!Object.keys(this.imageSettings).length) {
            // If no settings, just show the master image
            return [{
                image: this.imageStack!.master,
                type: 'master',
                visible: true,
                opacity: 1,
                normalizedOpacity: 1
            }];
        }

        const allImageSettings = Object.keys(this.imageSettings).map((key) => this.imageSettings[key]);
        const visibleImages = allImageSettings.filter((setting) => setting.visible);
        return visibleImages;
    }

    protected async mounted() {
        await this.mountedDone;
        this.loaded = true;
    }
}

