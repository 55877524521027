



























































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { EditionInfo } from '@/models/edition';
// import EditionIcons from '@/components/cues/edition-icons.vue';
import EditionService from '@/services/edition';

@Component({
    name: 'copy-edition-modal',
})
export default class CopyEditionModal extends Vue {
    @Prop() private visible!: boolean ;

    private editionService: EditionService = new EditionService();
    private newCopyName: string = '';
    private errorMessage: string = '';
    private waiting: boolean = false;

    private get user(): boolean {
        return this.$state.session.user ? true : false;
    }

    private get isWaiting(): boolean {
        return  !this.currentEdition;
    }

    private get currentEdition(): EditionInfo | null {
        return this.$state.editions.current;
    }

    private get canCopy(): boolean {
        return this.newCopyName.trim().length > 0;
    }

    private copyModalShown() {
        this.newCopyName = this.currentEdition!.name;
        if (this.user) {
            (this.$refs.newCopyNameRef as any).focus();
        }
    }

    private onShow( bvModalevt: Event ) {
        bvModalevt.preventDefault();
    }

    private onHide(evt: Event) {
      if ( evt.type === 'backdrop') {
        // evt.preventDefault();
        (this.$refs.newCopyNameRef as any).blur();
        (this.$refs.newCopyNameRef as any).hide();
      }
    }

    private async copyEdition(evt: Event) {
        evt.preventDefault();

        if (!this.canCopy) {
            return; // ENTER key calls this handler even if the button is disabled
        }
        this.newCopyName = this.newCopyName.trim();

        this.waiting = true;
        this.errorMessage = '';

        try {
            const newEdition = await this.editionService.copyEdition(
                this.currentEdition!.id,
                this.newCopyName
            );

            this.$state.editions.current = newEdition;

            this.$state.misc.newEditionId = newEdition.id;

            (this.$refs.copyModalRef as any).hide();

            this.$router.push({
                path: `/editions/${this.$state.misc.newEditionId}`,
            });


            // force refresh of the new artefact page so that
            // \components\artefact\artefact-image.vue
            // in artefact-card.vue
            // async mounted() will be successfully fullfiled
            this.$router.go(0);

        } catch (err) {
            this.errorMessage = err;
            console.error('Error copying an edition!', err);
        } finally {
            this.waiting = false;
        }
    }

    protected onLogin() {
        this.$root.$emit('bv::show::modal', 'loginModal');
        this.$bvModal.hide('copy-edition-modal');
    }

    protected onRegister() {
        this.$root.$emit('bv::show::modal', 'registerModal');
        this.$bvModal.hide('copy-edition-modal');
    }
}
