






















import { Component, Prop, Vue } from 'vue-property-decorator';
import { EditionInfo } from '@/models/edition';
import EditionPublicRow from './edition-public-row.vue';

@Component({
    name: 'editions-public-list',
    components: {
        EditionPublicRow,
    },
})
export default class EditionsPublicList extends Vue {
    @Prop() public editions!: EditionInfo[];

    protected get indices() {
        const indices: number[] = [];
        for (let idx = 0; idx < this.editions.length; idx += 4) {
            indices.push(idx);
        }

        return indices;
    }

    protected getWindowWidth() {
        return window.outerWidth;
    }
}
