

















import { Component, Prop, Emit, Vue } from 'vue-property-decorator';

import Navbar from '@/components/navigation/Navbar.vue';
import Waiting from '@/components/misc/Waiting.vue';
import SessionService from '@/services/session';
import { StateManager } from './state';
import CorruptedStateDialog from '@/components/misc/CorruptedStateDialog.vue';

import ScreenSizeAlert from './views/home/components/screen-size-alert.vue';


@Component({
    name: 'app',
    components: {
        Navbar,
        Waiting,
        CorruptedStateDialog,
        'screen-size-alert': ScreenSizeAlert,
    }
})

export default class App extends Vue {

    private waiting: boolean = true;
    private alertVisible: boolean = false;

    private created() {
        // Set the language
        this.$i18n.locale = this.$state.session.language;
        this.initializeApp();
        // Corrupted state event listener
        this.$state.eventBus.on(
            'corrupted-state',
            this.openCorruptedStateDialog
        );


        this.$nextTick( () =>
            window.addEventListener('resize', this.showScreenSizeAlert)
        );
    }

    private destroyed() {
        this.$state.eventBus.off(
            'corrupted-state',
            this.openCorruptedStateDialog
        );

        this.$nextTick( () =>
            window.removeEventListener('resize', this.showScreenSizeAlert)
        );
    }



    private showScreenSizeAlert(e: Event ) {
        e.preventDefault();

        const curOW = window.outerWidth;

        if ( curOW < 1000 ) {
            this.alertVisible = true;

            this.$nextTick( () => {
                this.alertVisible = true;
                this.$root.$bvModal.show('screen-size-alert');

            });

        } else {
            this.alertVisible = false;

            this.$nextTick( () => {
                this.alertVisible = false;
                this.$root.$bvModal.hide('screen-size-alert');
            });
        }


   }
    private async initializeApp() {
        const session = new SessionService();
        await session.isTokenValid();
        this.waiting = false;
    }

    private openCorruptedStateDialog() {
            this.$root.$emit('bv::show::modal', 'corrupted-state-dialog');
    }

}

