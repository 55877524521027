export default {
    cancel: 'Annuler',
    new: 'Nouveau Artefact',
    current: 'Actuel',
    copy: 'Cree une copie',
    anotherCopy: 'Cree une autre copie',
    permission: 'Autorisation',
    draw: 'Dessiner',
    box: 'Box',
    rename: 'Renommer',
    remove: 'Retirer',
    create: 'Créer',
    undo: 'Annuler',
    redo: 'Refaire',
    up: 'Vers le haut',
    down: 'Vers le bas',
    actions: 'Actions',
    metrics: 'Métrique',
    save: 'Enregistrer',
    load: 'Charge',
    auto: 'Auto',
    select: 'Sélectionner',
    collapsedsidebar: 'Menu TextFragment',
    collapsedsidebarObject: 'Menu ImageObject',
    collapsedsidebarArtefact: 'Menu Artefact',
    leftRotate: 'Rotation à gauche',
    rightRotate: 'Rotation à droite',
    editSign: 'Modifier le signe',
    deleteSign: 'Supprimer le signe',
    addToLeft: 'Ajouter à gauche',
    addToRight: 'Ajouter à droite'
};
