
























import { ImageStack } from '@/models/image';
import { ArtefactEditorParams } from '@/views/artefact-editor/types';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import ImageSettingsComponent from '../image-settings/ImageSettings.vue';
import { SingleImageSetting } from '../image-settings/types';
import Toolbox from './toolbox.vue';

@Component({
    name: 'adjust-image-toolbox',
    components: {
        'image-settings': ImageSettingsComponent,
        toolbox: Toolbox,
    },
})
export default class AdjustImageToolbox extends Vue {
    @Prop({ default: '' }) public subject!: string;
    @Prop() public imageStack!: ImageStack;
    @Prop() public params!: ArtefactEditorParams;

    protected onImageSettingChanged(event: SingleImageSetting) {
        this.$emit('image-settings-changed', event);
    }
}
