





















import { ArtefactDTO, DetailedSearchRequestDTO, ImageSearchResponseDTO, TextFragmentSearchResponseDTO } from '@/dtos/sqe-dtos';
import { Artefact } from '@/models/artefact';
import { EditionInfo } from '@/models/edition';
import { ImagedObject } from '@/models/imaged-object';
import SearchService from '@/services/search';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import EditionList from '../home/components/edition-list.vue';
import { SearchFormData, SearchResults } from './types';

interface ExpandedImagedObjectResponse extends ImageSearchResponseDTO {
    editions: EditionInfo[] | null;
}

@Component({
    name: 'text-fragment-results',
})
export default class TextFragmentResultComponent extends Vue {
    @Prop( { default: null })
    private textFragments!: TextFragmentSearchResponseDTO[] | null;

    private get title() {
        return `Text Fragments (${this.textFragments?.length || 0})`;
    }
}
