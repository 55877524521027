









































































import { Component, Prop, Emit, Vue } from 'vue-property-decorator';

import SessionService from '@/services/session';
import ErrorService from '@/services/error';
import ForgotPassword from '@/views/user/ForgotPassword.vue';
import router from '@/router';

@Component({
    name: 'login',
    components: {
        ForgotPassword,
    }
})

export default class Login extends Vue {

    public email: string = '';
    // email: this.$state.session ? this.$state.session.user!.email : '',
    public password: string = '';
    public errorMessage: string = '';
    private sessionService: SessionService = new SessionService();
    private errorService: ErrorService = new ErrorService(this);
    public waiting: boolean = false;


    public get disabledLogin(): boolean {
        return !this.email || !this.password || this.waiting;
    }

    public async login() {
        if (this.disabledLogin) {
            // Can be called due to ENTER key
            return;
        }

        try {
            this.waiting = true;
            await this.sessionService.login(this.email, this.password);
            this.close();
            router.push('/home');
            // Reload the personal editions
            location.reload();
        } catch (err: any) {
            this.errorMessage = this.errorService.getErrorMessage(
                err.response.data
            );
        } finally {
            this.waiting = false;
        }
    }

    private close() {
        (this.$refs.loginModalRef as any).hide();
    }

    public shown(): void {
        this.errorMessage = '';
        this.waiting = false;
        (this.$refs.email! as any).focus();
    }

    public forgotPassword() {
        this.$root.$emit('bv::show::modal', 'passwordModal');
        this.$bvModal.hide('loginModal');
    }

    public register() {
        this.$root.$emit('bv::show::modal', 'registerModal');
        this.$bvModal.hide('loginModal');
    }

}

