



























































import SessionService from '@/services/session';
import { Component, Prop, Emit, Vue } from 'vue-property-decorator';

@Component({
    name: 'ReportProblemModal',
    components: {},
})
export default class ReportProblemModal extends Vue {
    public username: string = '';
    public description: string = '';
    public title = '';
    public reported = false;
    protected sessionService: SessionService = new SessionService();

    public get readyToReport() {
        return (this.loggedIn || this.username.trim() !== '') && this.title.trim() !== '' && this.description.trim() !== '';
    }

    public get loggedIn() {
        return !!this.$state.session.user;
    }

    public async reportProblem() {
        let actualUsername = this.username;
        if (this.loggedIn) {
            actualUsername = this.$state.session.user?.email || '';
        }
        if (!actualUsername) {
            actualUsername = 'Not Specified';
        }
        const titleWithUserName = this.title + ' (' + actualUsername + ')';
        await this.sessionService.reportProblem({
            username: actualUsername,
            title: titleWithUserName,
            comment: this.description,
            url: window.location.toString(),
        });
        this.reported = true;
    }

    public close() {
        (this.$refs.ReportProblemModalRef as any).hide();
    }

    public mounted() {
        this.$root.$on('bv::modal::show', (bvEvent: any, modalId: string) => {
            if (modalId === 'ReportProblemModal') {
                this.init();
            }
        });
    }

    private init() {
        this.reported = false;
        this.username = '';
        this.title = this.$state.misc.reportIssueData?.title || '';
        this.description = this.$state.misc.reportIssueData?.description || '';
        this.$state.misc.reportIssueData = undefined;
    }
}
