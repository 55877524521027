






















































import { Component, Prop, Emit, Vue } from 'vue-property-decorator';

import SessionService from '@/services/session';
import ErrorService from '@/services/error';
// import ForgotPassword from '@/views/user/ForgotPassword.vue';

@Component({
    name: 'forgot-password',
})

export default class ForgotPassword extends Vue {

    // data

    protected email: string = '';
    protected errorMessage: string = '';
    protected sessionService: SessionService = new SessionService();
    protected errorService: ErrorService = new ErrorService(this);
    protected waiting: boolean = false;


    // computed
    public get disabledSubmit(): boolean {
        return !this.email || this.waiting;
    }

    // methods

    protected async submit() {
        if (this.disabledSubmit) {
            // Can be called due to ENTER key
            return;
        }

        try {
            this.waiting = true;
            await this.sessionService.forgotPassword(this.email);
            this.close();

            this.$toasted.show(this.$tc('toasts.reset'), {
                type: 'info',
                position: 'top-right',
                duration: 7000,
            });
        } catch (err) {
            this.errorMessage = this.errorService.getErrorMessage(
                err.response.data
            );
        } finally {
            this.waiting = false;
        }
    }

    protected close() {
        (this.$refs.passwordModalRef as any).hide();
    }

    protected shown() {
        this.errorMessage = '';
        this.waiting = false;
        (this.$refs.emailRef as any).focus();
    }

}

