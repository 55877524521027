






















import { Component, Prop, Emit, Vue } from 'vue-property-decorator';

import SessionService from '@/services/session';
import ErrorService from '@/services/error';
import router from '@/router';
import { ResetForgottenUserPasswordRequestDTO } from '@/dtos/sqe-dtos';



@Component({
     name: 'activation'
})

export default class Activation extends Vue {

  // data

  protected token: string = '';
  protected errorMessage: string = '';
  protected sessionService: SessionService = new SessionService();
  protected errorService: ErrorService = new ErrorService(this);
  protected waiting: boolean = false;

  protected mounted() {
    const url  = window.location.href;
    this.token = url.split('token/')[1];
    if (this.token === '') {
      console.error('There is no token in url');
    }
  }

  // methods

  public async change() {
    const data = {
      token: this.token
    } as ResetForgottenUserPasswordRequestDTO;
    this.waiting = true;
    try {
      await this.sessionService.activateUser(data);
      router.push('/');
      this.$root.$emit('bv::show::modal', 'loginModal');
    } catch (e) {
      this.errorMessage = this.errorService.getErrorMessage(e.response.data);
    } finally {
      this.waiting = false;
    }
  }

}

