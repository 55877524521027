










































import { Artefact } from '@/models/artefact';
import { EditionInfo } from '@/models/edition';
import { ImagedObject } from '@/models/imaged-object';
import ArtefactService from '@/services/artefact';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    name: 'copy-to-edition-modal',
})
export default class CopyToEditionModal extends Vue {
    public searchValue = '';
    public editionTargetId: number = 0;
    public editionTargetName: string = '';
    private errorMessage: string = '';
    private waiting: boolean = false;
    private filteredEditions: EditionInfo[] = [];
    private artefactService: ArtefactService = new ArtefactService();
    public get editions() {
        this.filteredEditions = this.$state.editions.items;
        return this.filteredEditions
            .filter((ed) => !ed.isPublic)
            .filter((x: EditionInfo) =>
                x.name.toLowerCase().includes(this.searchValue.toLowerCase())
            );
    }

    private get currentArtefact(): Artefact {
        return this.$state.artefacts.current!;
    }

    private get imagedObject(): ImagedObject | null {
        return this.$state.imagedObjects.current;
    }

    private async copyToEdition() {
        this.waiting = true;
        this.errorMessage = '';

        try {
            const artefactCopy = await this.artefactService.copyArtefact(
                this.editionTargetId,
                this.currentArtefact
                );

            (this.$refs.copyToEditionModalRef as any).hide();

            this.$router.push({
                path: `/editions/${this.editionTargetId}/artefacts/${artefactCopy.id}`,
            });

            this.$router.go(0);
        } catch (err: any) {
            this.errorMessage = err.toString();
            console.error('Error copying artefact', err);
        } finally {
            this.waiting = false;
        }
    }
    private onHide(evt: Event) {
        // (this.$refs.copyToEditionModalRef as any).blur();
        (this.$refs.copyToEditionModalRef as any).hide();
    }
}
