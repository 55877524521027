































import { EditionInfo } from '@/models/edition';
import EditionService from '@/services/edition';
import CopyEditionModal from '@/views/home/components/copy-edition-modal.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Toolbox from './toolbox.vue';

@Component({
    name: 'copy-edition-toolbox',
    components: {
        'copy-edition-modal': CopyEditionModal,
        toolbox: Toolbox,
    },
})
export default class CopyEditionToolbox extends Vue {
    public editionService: EditionService = new EditionService();
    public variantEditions: EditionInfo[] = [];

    private get currentEdition(): EditionInfo | null {
        return this.$state.editions.current;
    }

    private openCopyEdtion() {
        // this.$root.$emit('bv::show::modal', 'copy-edition-modal');
        this.$root.$bvModal.show('copy-edition-modal');
    }

    private async mounted() {
        if (this.currentEdition) {
            const variantEditionList =
                await this.editionService.getManuscriptEditions(
                    this.currentEdition.manuscriptId
                );
            this.variantEditions = variantEditionList.editions
                .flatMap((x) => x[0])
                .filter((e) => e && !e.isPublic && e.id !== this.currentEdition?.id)
                .map((e) => new EditionInfo(e));
        }
    }
}
