



























import { Component, Prop, Emit, Vue } from 'vue-property-decorator';

import { ImagedObjectEditorParams } from './types';
import { Polygon } from '@/utils/Polygons';
import { SingleImageSetting } from '../../components/image-settings/types';
import IIIFImageComponent from '@/components/images/IIIFImage.vue';

@Component({
name: 'image-layer',
  components: {
    'iiif-image': IIIFImageComponent,
  }
})

export default class ImageLayer extends Vue {

  // props
    @Prop() protected width!: number;
    @Prop() protected height!: number;
    @Prop() protected params!: ImagedObjectEditorParams;
                        // Object as () => ImagedObjectEditorParams;
    @Prop() protected editable!: boolean;
    @Prop() protected clippingMask!: Polygon;
                        // Object as () => Polygon;

  // computed

    protected get fullImageMask(): string {
      return `M0 0L${this.width} 0L${this.width} ${this.height}L0 ${this.height}`;
    }

    protected get imageSettings(): SingleImageSetting[] {
      const values = Object.keys(this.params.imageSettings).map((key) => this.params.imageSettings[key]);
      return values;
    }

    protected get visibleImageSettings(): SingleImageSetting[] {
      return this.imageSettings.filter((image) => image.visible);
    }

}

