

































import { Component, Prop, Vue } from 'vue-property-decorator';
import { IIIFImage } from '@/models/image';
import { Polygon } from '@/utils/Polygons';
import { SingleImageSetting } from '../../components/image-settings/types';
import { BaseEditorParams } from '@/models/editor-params';
import { BoundingBox } from '@/utils/helpers';
import IIIFImageComponent from '@/components/images/IIIFImage.vue';
import { Artefact } from '@/models/artefact';

@Component({
    name: 'image-layer',
    components: {
        'iiif-image': IIIFImageComponent,
    }
})
export default class ImageLayer extends Vue {
    @Prop() public width!: number;
    @Prop() public height!: number;
    @Prop() public params!: BaseEditorParams;
    // @Prop() public editable!: boolean;
    @Prop() public clippingMask!: Polygon;
    @Prop() public boundingBox!: BoundingBox;
    @Prop() public artefact!: Artefact;

    public get imageSettings(): SingleImageSetting[] {
        const values = Object.keys(this.params.imageSettings).map(
            key => this.params.imageSettings[key]
        );
        return values;
    }
    public get visibleImageSettings(): SingleImageSetting[] {
        return this.imageSettings.filter(image => image.visible);
    }
}
