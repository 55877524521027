export default {
    cancel: 'Delete ROI',
    new: 'New Artefact',
    current: 'Current',
    copy: 'Copy Edition',
    copyToEdition: 'Copy To Edition',
    anotherCopy: 'Create another copy',
    permission: 'Permission',
    draw: 'Draw',
    erase: 'Erase',
    box: 'Box',
    rename: 'Rename',
    remove: 'Remove',
    create: 'Create',
    undo: 'Undo',
    redo: 'Redo',
    up: 'Switch Up',
    down: 'Switch Down',
    actions: 'Actions',
    metrics: 'Metrics',
    save: 'Save',
    load: 'Load',
    add: 'Add',
    invite: 'Invite',
    auto: 'Auto',
    select: 'Select',
    edit: 'Edit',
    view: 'View',
    side: 'Side',
    collapsedsidebar: 'Menu TextFragment',
    collapsedsidebarObject: 'Menu ImageObject',
    collapsedsidebarArtefact: 'Menu Artefact',
    leftRotate: 'Left Rotate',
    rightRotate: 'Right Rotate',
    editSign: 'Edit sign',
    deleteSign: 'Delete sign',
    editLine: ' Edit line',
    addLineBefore: ' Add a line before',
    addLineAfter: ' Add a line after',
    deleteLine: 'Delete this line',
    addToLeft: 'Add to left',
    addToRight: 'Add to right',
    showQwbVariants: 'Show QD Variants',
    showQwbParallels: 'Show QD Parallels',
    noImages: 'No Images Available',
    report: 'Report'
};
