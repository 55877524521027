


































































































import { Component, Prop, Emit, Vue } from 'vue-property-decorator';
import { SignInterpretation, Sign, Line } from '@/models/text';
import QwbProxyService from '@/services/qwb-proxy';
import EditSignModal from './edit-sign-modal.vue';
import { OperationsManager, SavingAgent } from '@/utils/operations-manager';
import {
    ArtefactROIOperation,
    DeleteSignInterpretationOperation,
} from '../../views/artefact-editor/operations';
import { Artefact } from '@/models/artefact';
import { QwbWordVariantListDTO } from '@/dtos/sqe-dtos';

@Component({
    name: 'text-sign',
})
export default class TextSign extends Vue {
    @Prop() public sign!: Sign;
    @Prop() public withMenu!: boolean;
    private previousMenuId: string = '';
    private qwbVariants: QwbWordVariantListDTO | null = null;
    private contenteditable: boolean = false;
    private get readOnly(): boolean {
        return this.$state.editions.current!.permission.readOnly;
    }

    private get editingMode() {
        return this.$state.textFragmentEditor.textEditingMode;
    }

    // Each sign offers alternative readings. For now we always show the first suggestion
    private get si() {
        return this.sign.signInterpretations[0];
    }

    private get isSelected() {
        return this.$state.textFragmentEditor.isSiSelected(this.si);
    }

    private get isHighlighted() {
        return (
            this.$state.artefactEditor.highlightCommentMode &&
            (this.si.commentary ||
                this.si.attributes.some((attr) => attr.commentary))
        );
    }

    private get cssStrings(): string {
        return this.si.attributes
            .map((x) =>
                `${x.attributeString}-${x.attributeValueString}`
                    .toLowerCase()
                    .replace('_', '-')
            )
            .join(' ');
    }

    private get virtualArtefact(): Artefact | null {
        if (this.si.rois.length !== 1) {
            return null;
        }

        const artefact = this.$state.artefacts.find(this.si.rois[0].artefactId);
        if (!artefact) {
            return null;
        }

        return artefact.isVirtual ? artefact : null;
    }

    private get qwbWordId(): number | undefined {
        const allQwbWordIds = this.sign.signInterpretations.flatMap(
            (x) => x.qwbWordIds
        );
        return allQwbWordIds.length > 0 ? allQwbWordIds[0] : undefined;
    }
    public get textFragmentEditorState() {
        return this.$state.textFragmentEditor;
    }

    private deleteSignInterpretation(si: SignInterpretation) {
        const delOps = this.si.rois.map(
            (roi) => new ArtefactROIOperation('erase', roi)
        );
        const op = new DeleteSignInterpretationOperation(this.si.id);

        op.redo(true); // First delete the sign, which uses the ROIs to update the artefacts SI caches
        for (const delOp of delOps) {
            // Now delete the ROIs
            delOp.redo(true);
        }
        this.$state.eventBus.emit('new-bulk-operations', [...delOps, op]);
    }

    private onSignInterpretationClicked(event: MouseEvent) {
        if (event.ctrlKey || event.metaKey) {
            this.$state.textFragmentEditor.toggleSelectSign(this.si);
        } else {
            this.$state.textFragmentEditor.selectSign(this.si);
        }
    }

    // @Emit()
    // private editLine() {
    //     return true;
    // }
    // private openEditLineModal() {
    //     this.$root.$emit('bv::show::modal', 'editLineModal');
    // }

    private openEditSignModal() {
        this.$state.textFragmentEditor.modeSignModal = 'edit';
        this.$root.$emit('bv::show::modal', 'editSignModal');
    }

    private openAddLeftSignModal() {
        this.$state.textFragmentEditor.modeSignModal = 'create';
        this.$root.$emit('bv::show::modal', 'editSignModal');
    }

    private openAddRightSignModal() {
        this.$state.textFragmentEditor.modeSignModal = 'create';
        const si =
            this.si.sign.line.signs[this.si.sign.indexInLine - 1]
                .signInterpretations[0];
        this.$state.textFragmentEditor.selectSign(si);
        this.$root.$emit('bv::show::modal', 'editSignModal');
    }

    private async openQwbVariantsModal() {
        if (this.qwbWordId !== undefined) {
            if (this.qwbVariants === null && this.qwbWordId !== undefined) {
                const qps = new QwbProxyService();
                this.qwbVariants = await qps.getQwbWordVariants(this.qwbWordId);
            }
            this.$bvModal.show(`qwb-word-${this.si.signInterpretationId}`);
        }
    }

    private openSignMenu(event: MouseEvent, signMenuId: string) {
        // prevent usual menu to display
        event.preventDefault();
        this.$state.textFragmentEditor.selectSign(this.si);

        this.$root.$emit('bv::show::popover', signMenuId);
        this.previousMenuId = signMenuId;
    }

    private openEditVirtualArtefact() {
        if (!this.virtualArtefact) {
            console.warn(
                "Can't edit virtual artefact when no virtual artefact is set"
            );
            return;
        }
        this.$state.textFragmentEditor.editedVirtualArtefact =
            this.virtualArtefact;

        this.$state.showEditReconTextBar = true;
        // this.showReconTextEditor();
        // this.$emit('showReconTextEditor');

        // this.$root.$emit('bv::show::modal', 'editVirtualArtefactText');
    }

    @Emit()
    private showReconTextEditor() {
        return true;
    }

    private closeSignMenu() {
        this.$root.$emit('bv::hide::popover', this.previousMenuId);
    }

    private focusPopover() {
        (this.$refs.signMenu as any).focus();
    }
}
