




















































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    name: 'ScreenSizeAlert',
    components: {
    }
})

export default class ScreenSizeAlert extends Vue {

     @Prop({default: false}) private visible!: boolean ;

}

