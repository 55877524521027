






































































// import Vue from 'vue';
import { Component, Prop, Emit, Vue } from 'vue-property-decorator';

import EditionSidebar from './components/sidebar.vue';
import { EditionInfo } from '@/models/edition.js';
import Waiting from '@/components/misc/Waiting.vue';

import { Artefact } from '@/models/artefact';

import PermissionModal from './components/permission-modal.vue';
import EditionMetadataModal from './components/metadata.vue';
import DeleteEditionModal from './components/delete-edition-modal.vue';

@Component({
    name: 'edition',
    components: {
        EditionSidebar,
        Waiting,
        PermissionModal,
        'delete-edition-modal': DeleteEditionModal,
        'edition-metadata-modal': EditionMetadataModal,
    },
})
export default class Edition extends Vue {
    // protected (not private) to allow future inheritance
    // =======================================================

    // protected waiting: boolean = true;

    public editionId: number = 0;
    protected page: string = '';
    protected isLoading: boolean = false;

    public get isWaiting(): boolean {
        return this.isLoading;
    }

    private get user(): boolean {
        return this.$state.session.user ? true : false;
    }

    public get currentEdition(): EditionInfo | null {
        return this.$state.editions.current!;
    }

    public get isAdmin(): boolean {
        return this.currentEdition!.permission.isAdmin;
    }

    private get copyTooltip(): string {
        const publicStr = this.currentEdition!.isPublic
            ? 'This is a public Edition. '
            : '';

        return (
            (this.currentEdition!.isPublic
                ? 'This is a public Edition. Create a copy in order to'
                : 'Create a copy and') + ' Edit this Edition'
        );
    }

    public openMetadata() {
        this.$root.$emit('bv::show::modal', 'editionMetadataModal');
    }
    public deleteEdition() {
       this.$root.$emit('bv::show::modal', 'deleteEditionModal');
  }
    public get artefactsLength(): number {
        const virtualCount = this.$state.artefacts.items.reduce(
            (count, art: Artefact) => {
                if (art.isVirtual) {
                    count++;
                }
                return count;
            },
            0
        );

        return this.$state.artefacts.items.length - virtualCount;
    }

    public get imagedObjectsLength(): number {
        return this.$state.imagedObjects.items.length;
    }

    // This code is not in the created method since it's asynchronous,
    // and Vue doesn't wait for an asynchornous created to finish
    // before calling mounted.
    // Instead of adding synchronization between created and mounted,
    //  we just moved it to mounted.
    // ( same mechanism as in scroll-editor.vue )

    protected async mounted() {
        this.isLoading = true;
        this.editionId = parseInt(this.$route.params.editionId, 10);

        // Wait for editionInfo object to be valid
        // (in order not to get run-time undefined errors)
        // $state.prepare.edition is an async edition(editionId: number): Promise<void>
        //  returning an editionInternal(id));    }

        await this.$state.prepare.edition(this.editionId);

        // Added as in scroll-editor.vue
        const currentEdition = this.$state.editions.find(this.editionId);

        // This is done also in scroll-editor
        if (currentEdition) {
            this.$state.editions.current = currentEdition;
            this.$state.artefacts.current = null;
            this.$state.imagedObjects.current = null;
        }

        this.isLoading = false;
        this.getPage(window.location.href);
    }

    // Navigation Guards:
    // Fetching data Before Navigation to new route,
    // when route changes and this component is already rendered.
    // We're still in prev view
    // while resource is being fetched for the incoming view
    // ==========================================================

    protected async beforeRouteUpdate(to: any, from: any, next: () => void) {
        this.editionId = parseInt(to.params.editionId, 10);

        // Wait for editionInfo object to be valid
        await this.$state.prepare.edition(this.editionId);

        this.getPage(to.path);

        // call next() when fetch complete
        next();
    }

    // methods => member functions of the class
    // ============================================================

    public openPermissionModal() {
        this.$root.$emit('bv::show::modal', 'permissionModal');
        // event, new_value
    }

    public versionString(ver: EditionInfo): string {
        return ver.name;
    }

    protected getPage(url: string) {
        if (url.endsWith('artefacts')) {
            this.page = 'artefacts';
        } else {
            this.page = 'imaged-objects';
        }
    }
}
