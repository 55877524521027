


























import { Component, Vue } from 'vue-property-decorator';
import Waiting from '@/components/misc/Waiting.vue';
@Component({
    name: 'edition-metadata-modal',
    components: {
        Waiting,
    },
    filters: {
        cleanString(value: string) {
            return value.replace(/\$/g, '');
        },
    },
})
export default class EditionMetadataModal extends Vue {
    public editionId: number = 0;

    public get edition() {
        return this.$state.editions.current!;
    }

    public keys = [
        'manuscript',
        'composition',
        'copy',
        'abbreviation',
        'site',
        'manuscriptType',
        'compositionType',
        'period',
        'language',
        'script',
        'material',
        'otherIdentifications',
        'publication',
    ];
    private static _headers = {
        manuscript: 'Manuscript',
        composition: 'Composition',
        copy: 'Copy',
        abbreviation: 'Abbreviation',
        site: 'Site',
        manuscriptType: 'Manuscript Type',
        compositionType: 'Composition Type',
        period: 'Period',
        language: 'Language',
        script: 'Script',
        material: 'Material',
        otherIdentifications: 'Other Identifications',
        publication: 'Publication',
    };

    // Return the following as 'any' so that eslint doesn't complain about headers[key] above
    public get headers(): any {
        return EditionMetadataModal._headers;
    }
    public get metadata(): any {
        return this.edition.metadata;
    }

    protected async mounted() {
        this.editionId = parseInt(this.$route.params.editionId, 10);
        if (isNaN(this.editionId)) {
            return;
        }
        await this.$state.prepare.edition(this.editionId);
    }

    public cleanString(): any {
        // This is a placeholder to remove the error when calling the cleanString filter.
        // Without this, Typescript complains that cleanString is not defined, even though Vue
        // knows it should call the function defined as the filter.
        return 'WRONG FILTER'; // If you see this in the metadata, you know the function is called when it shouldn't.
    }
}
