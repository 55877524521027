
































import { ArtefactDTO, DetailedSearchRequestDTO, ImageSearchResponseDTO } from '@/dtos/sqe-dtos';
import { Artefact } from '@/models/artefact';
import { EditionInfo } from '@/models/edition';
import { ImagedObject } from '@/models/imaged-object';
import SearchService from '@/services/search';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import EditionList from '../home/components/edition-list.vue';
import { SearchFormData, SearchResults } from './types';

interface ExpandedImagedObjectResponse extends ImageSearchResponseDTO {
    editions: EditionInfo[] | null;
}

@Component({
    name: 'imaged-object-results',
})
export default class ImagedObjectResultComponent extends Vue {
    @Prop( { default: null })
    private imagedObjects!: ImageSearchResponseDTO[] | null;
    private get expandedObjects() {
        const expanded = [] as ExpandedImagedObjectResponse[];

        for (const im of this.imagedObjects || []) {
            let editions: EditionInfo[] | null = null;
            if (im.editionIds) {
                for (const editionId of im.editionIds) {
                    const edition = this.$state.editions.find(editionId);
                    if (!edition) {
                        console.warn(`Can't locate edition ${editionId} returned by search`);
                    } else {
                        if (!editions) {
                            editions = [];
                        }
                        editions.push(edition);
                    }
                }
            }
            expanded.push({ editions, ...im});
        }

        return expanded;
    }
    private ready = false;

    private async mounted() {
        this.ready = false;
        await this.$state.prepare.allEditions();
        this.ready = true;
    }

    private get title() {
        return `Imaged Objects (${this.imagedObjects?.length || 0})`;
    }
}
